<template>
    <!-- nvl 2 -->
    <intranet-nav-content>
        <template slot="links">
            <intranet-nav-content-link-exact
                :link="{ name: 'Agenda', path: { name: 'employee-schedule-dashboard' } }"/>
            <!-- //* -->
            <intranet-nav-content-link
                :link="{ name: 'Crear', path: { name: 'employee-schedule-SA-interview' } }"
                v-show="hasSalesAdvisorPermission && hasCulturalAdvisorPermission"/>

            <intranet-nav-content-link
                :link="{ name: 'Crear', path: { name: 'employee-schedule-SA-interview' } }"
                v-show="hasSalesAdvisorPermission && !hasCulturalAdvisorPermission"/>
            <intranet-nav-content-link
                :link="{ name: 'Crear', path: { name: 'employee-schedule-CA-interview' } }"
                v-show="hasCulturalAdvisorPermission && !hasSalesAdvisorPermission"/>
            <!-- //* -->
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';

export default {
    name: 'Schedule',
    components: { 
        IntranetNavContent,
        IntranetNavContentLink,
        IntranetNavContentLinkExact
    },
    computed: {
        hasSalesAdvisorPermission() {
            return this.$store.getters['user/hasEmployeePermission']('asesor-ventas')
        },
        hasCulturalAdvisorPermission() {
            return this.$store.getters['user/hasEmployeePermission']('asesor-cultural')
        },
    }
};
</script>