<template>
    <b-row >
        <!-- participant details -->
        <b-col cols="12" lg="6" class="mb-4">
            <b-card class="shadow" >
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Detalles del Participante
                            </h3>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding programSeasonParticipant -->
                <div style="height:300px;" v-show="programSeasonParticipantDataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>
                
                <!-- programSeasonParticipant details -->
                <div v-show="!programSeasonParticipantDataLoading" class="mt-5">
                    <b-list-group>
                        <b-list-group-item>
                            <b-row>
                                <b-col>Nombres</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonParticipantData.attributes.user_name }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Apellidos</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonParticipantData.attributes.user_last_name }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Documento</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonParticipantData.attributes.user_document_number }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Celular</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonParticipantData.attributes.user_phone_number }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Correo</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonParticipantData.attributes.user_email }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-card>
        </b-col>

        <!-- programSeason details -->
        <b-col cols="12" lg="6">
            <b-card class="shadow" >
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0  text-primary text-button">
                                Detalles del Programa
                            </h3>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:300px;" v-show="programSeasonParticipantDataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <div v-show="!programSeasonParticipantDataLoading" class="mt-5">
                    <b-list-group>
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Programa</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonParticipantData.attributes.program }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Temporada</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonParticipantData.attributes.season }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-show="programSeasonParticipantData.attributes.postulant_salesAdvisor_id">
                            <b-row>
                                <b-col cols="4">Asesor ventas</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <div>
                                        {{ programSeasonParticipantData.attributes.postulant_salesAdvisor_full_name }}
                                    </div>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Asesor Cultural</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <div>
                                        {{ programSeasonParticipantData.attributes.culturalAdvisor_full_name }}
                                    </div>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Estado</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonParticipantData.attributes.status }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-card>
        </b-col>

        <!-- visa details -->
        <b-col cols="12" lg="6">
            <b-card class="shadow" >
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Detalles de VISA
                            </h3>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:300px;" v-show="programSeasonParticipantDataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <div v-if="!programSeasonParticipantDataLoading" class="mt-5">
                    <b-list-group>
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">DS-160</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonParticipantData.attributes.ds_160 }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">DS-2019</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonParticipantData.attributes.ds_2019 }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-card>
        </b-col>

    </b-row>
</template>

<script>
import { getProgramSeasonParticipant } from '@/api/ProgramSeasonParticipant/ProgramSeasonParticipant';

export default {
    name: "ProgramDetails",
    data: () => ({
        programSeasonParticipantData: {
            attributes: {}
        },
        programSeasonParticipantDataLoading: false,
    }),
    created() {
        this.getData()
    },
    methods: {
        getData () {
            this.programSeasonParticipantDataLoading = true
            let IdProgramSeasonParticipant = this.$route.params.IdProgramSeasonParticipant // ok

            getProgramSeasonParticipant(IdProgramSeasonParticipant)
                .then(({ data }) => {
                    this.programSeasonParticipantData = data.data
                })
                .finally(() => {
                    this.programSeasonParticipantDataLoading = false
                })
        },

    }
}
</script>