<template>
    <b-row>
        <b-col cols="12" class="mb-4">
            <div class="intranet-nav-content">
                <intranet-nav-content-link-exact :link="{ name: 'Tópicos', path: { name: 'employee-trainee-capacitations-topics' } }"/>
                <!-- <intranet-nav-content-link :link="{ name: 'Contrato', path: { name: 'employee-postulants-contract-SA' } }"/> -->
            </div>
        </b-col>

        <b-col>
            <slide-y-down-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-y-down-transition>
        </b-col>
    </b-row>

</template>

<script>
// import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import { SlideYDownTransition  } from 'vue2-transitions';

export default {
    name: 'TraineeCapacitations',
    components: { 
        // IntranetNavContentLink,
        IntranetNavContentLinkExact,
        SlideYDownTransition,
    },
};
</script>

<style scoped>
.intranet-nav-content {
    display: flex;
    align-items: center;
    overflow-x:auto;
    white-space:nowrap;
}
</style>