<template>
    <attempt-index-component
        routeNameAtemptDetails="employee-participants-attempt-details"
    ></attempt-index-component>
</template>

<script>
import AttemptIndexComponent from '@/components/Admin-Employee/Participant/ParticipantAttempts/Attempt/AttemptIndexComponent.vue'

export default {
    name:'Attemp',
    components: { AttemptIndexComponent },
}
</script>