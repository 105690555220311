<template>
    <b-row>
        <b-col>
            <slide-y-down-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-y-down-transition>
        </b-col>
    </b-row>

</template>

<script>
import { SlideYDownTransition  } from 'vue2-transitions';

export default {
    name: 'Sponsors',
    components: {
        SlideYDownTransition,
    },
};
</script>