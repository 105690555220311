<template>
    <b-row >
        <!-- postulant details -->
        <b-col cols="12" class="mb-4">
            <b-card class="shadow">
                <b-card-header class="pt-0 pb-  2" >
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                  Programación de las ofertas laborales
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData" :disabled="loading">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding  -->
                <div style="height:100px;" v-show="loading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>
                
                <!-- programSeasonSponsor details -->
                <div v-show="!loading" class="mt-5">
                    <b-row>
                        <b-col cols="12" md="6">
                            <b-row>
                                <b-col class="text-center">
                                    <div>
                                        Existirá un cronjob que se ejecutará cada 10 minutos.
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col class="text-right">
                                    <div>
                                        <b-button variant="transparent" size="sm" @click="modalClick"> <b-icon icon="pencil" variant="naranja" scale="1.3"></b-icon></b-button>
                                    </div>
                                </b-col>
                            </b-row>


                            <b-list-group>
                                <b-list-group-item>
                                    <b-row>
                                        <b-col>Fecha</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col>{{ programSeasonSponsor.start_date_format }}</b-col>
                                    </b-row>
                                </b-list-group-item>

                                <b-list-group-item>
                                    <b-row>
                                        <b-col>Hora</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col>{{ programSeasonSponsor.start_time }}</b-col>
                                    </b-row>
                                </b-list-group-item>

                                <b-list-group-item>
                                    <b-row>
                                        <b-col>¿Agendado?</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col>{{ programSeasonSponsor.schedule }}</b-col>
                                    </b-row>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>
                </div>

                <b-modal
                    id="modal-update-programation"
                    centered
                    hide-footer
                    size="md"
                    no-close-on-backdrop
                    no-close-on-esc
                    hide-backdrop
                    header-class="pb-0"
                >
                    <!-- modal header -->
                    <template #modal-header>
                        <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                            <b-row align-h="between" align-v="center">
                                <b-col cols="auto"><h3 class="text-primary mb-0">ACTUALIZAR</h3></b-col>
                                <b-col cols="auto">
                                    <b-button size="sm" variant="magenta" @click="modalClose">
                                        <b-icon icon="x" scale="1.5"/>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </template>

                    <!-- modal content -->
                    <template #default>
                        <b-container>
                            <b-row>
                                <!-- form -->
                                <b-col cols="12">
                                    <b-form @submit.prevent="updateData">
                                        <b-form-row>
                                            <!-- start_date -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Fecha :"
                                                >
                                                    <pulse-loader
                                                        :loading="updateLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-datepicker
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        selected-variant="primary"
                                                        today-variant="naranja"
                                                        locale="es"

                                                        today-button
                                                        label-today-button="today"
                                                        today-button-variant="outline-naranja"
                                                        
                                                        reset-button
                                                        label-reset-button="reset"
                                                        reset-button-variant="outline-magenta"

                                                        close-button
                                                        label-close-button="ok"
                                                        close-button-variant="primary"

                                                        v-model="selected.start_date"
                                                        :state="showInputStatus('start_date')"
                                                        v-if="!updateLoading"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('start_date')"
                                                        :key="`${i}-interview-start_date`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                           <!-- start_time -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Hora de inicio :"
                                                    description="Intervalos de 10 minutos"
                                                >
                                                    <pulse-loader
                                                        :loading="updateLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-timepicker
                                                        locale="en"

                                                        now-button
                                                        label-now-button="now"
                                                        now-button-variant="outline-naranja"

                                                        reset-button
                                                        label-reset-button="reset"
                                                        reset-button-variant="outline-magenta"

                                                        close-button
                                                        label-close-button="ok"
                                                        close-button-variant="primary"

                                                        minutes-step="10"

                                                        v-model="selected.start_time"
                                                        :state="showInputStatus('start_time')"
                                                        v-if="!updateLoading"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('start_time')"
                                                        :key="`${i}-interview-start_time`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                             <!-- start_time -->
                                             <b-col cols="12">
                                                <b-form-group
                                                    label="Agendar :"
                                                >
                                                    <pulse-loader
                                                        :loading="updateLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-select
                                                        :options="[{ value: 'no', text: 'no' }, { value: 'si', text: 'si' }]"
                                                        v-model="selected.schedule"
                                                        :state="showInputStatus('schedule')"
                                                        v-if="!updateLoading"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('schedule')"
                                                        :key="`${i}-interview-schedule`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>



                                        </b-form-row>

                                        <b-row align-v="center" align-h="center">
                                            <b-col cols="auto">
                                                <b-button variant="primary" type="submit">
                                                    ACTUALIZAR
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </b-col>
                            </b-row>
                        </b-container>
                    </template>
                </b-modal>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getProgramSeasonSponsorProgramation, updateProgramation } from '@/api/ProgramSeasonSponsor/Programation'; 
// import swal from 'sweetalert';

export default {
    name: "ProgramSponsorProgramation",
    data: () => ({
        programSeasonSponsor: {},
        loading: false,
        selected: {},
        updateLoading: false,
        //
        initialInputValues : true,
        inputErrors: {},
    }),
    created() {
        this.getData()
    },
    methods: {
        getData () {
            this.loading = true

            getProgramSeasonSponsorProgramation(this.$route.params.IdProgramSeasonSponsor)
                .then(({ data }) => {
                    this.programSeasonSponsor = data.data.attributes
                })
                .finally(() => {
                    this.loading = false
                })
        },

        modalClick () {
            this.selected = {...this.programSeasonSponsor}
            this.$bvModal.show('modal-update-programation')
        },
        modalClose () {
            this.$bvModal.hide('modal-update-programation')
            this.clearInputsAndStates()
        },
        updateData () {
            this.updateLoading = true
            this.initialInputValues = false

            let programationForm = new FormData()
            programationForm.append('.method', 'put')
            programationForm.append('start_date', this.selected.start_date)
            programationForm.append('start_time', this.selected.start_time)
            programationForm.append('schedule', this.selected.schedule)

            updateProgramation(this.selected.id ,programationForm)
                .then(({ data }) => {
                    this.programSeasonSponsor = data.data.attributes
                    this.modalClose()
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.updateLoading = false
                })
        },

        // global input methods
        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialInputValues = true
            this.inputErrors = {}
            this.selected = []
        },
        reloadData () {
            this.getData()
        }

    }
}
</script>