<template>
    <contract-pdf-list-component :routeNameToRegister="routeName"></contract-pdf-list-component>
</template>

<script>
import ContractPdfListComponent from '@/components/HotFix/Contract/ContractPdf/ContractPdfListComponent.vue'

export default {
    name: 'ContractPdfList',
    components: { ContractPdfListComponent },
    data: () => ({
        routeName: 'employee-hotfix-contracts-register-html'
    })
}
</script>