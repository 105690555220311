import { get } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//get
function getProgramSeasonPostulants (pPage, pIdPostulant) {
    const URI = `${BASE_URL}/api/postulants/${pIdPostulant}/programSeasonPostulants?page=${pPage}`
    return get(URI, { headers })
}

export {
    getProgramSeasonPostulants
}