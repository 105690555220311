<template>
    <participant-visa-document-component :ui="ui"></participant-visa-document-component>
</template>

<script>
import ParticipantVisaDocumentComponent from '@/components/Admin-Employee-Participant/Visa/ParticipantVisaDocumentComponent.vue';

export default {
    name: "Visa",
    components: { ParticipantVisaDocumentComponent },
    data: () => ({
        ui: "employee"
    }),
}
</script>