<template>
    <b-row>
        <!-- sponsor name -->
        <b-col cols="12" class="mb-4">
            <div class="intranet-nav-content">
                <div v-if="programSeasonSponsorLoading">
                    <pulse-loader
                        :color="'#fff'"
                        :margin="'8px'"
                        :size="5">
                    </pulse-loader>
                </div>

                <div v-else>
                    <!-- <intranet-nav-content-link-exact :link="{
                        name: `${programSeasonSponsor.sponsor_name}`, -->
                    <intranet-nav-content-link-exact :link="{
                            name: `job offers`,
                            path: { name: 'participant-program-sponsor-joboffers', params : { 'IdProgramSeasonSponsor' : programSeasonSponsor.id } }
                        }">
                    </intranet-nav-content-link-exact>
                </div>
            </div>
        </b-col>

        <!-- job offers -->
        <b-col>
            <b-row>
                <b-col>
                    <b-card class="shadow" no-body>
                        <b-card-header class="border-0">
                            <b-row class="align-items-center">
                                <b-col>
                                    <h3 class="mb-0 text-primary text-button">
                                        Ofertas laborales
                                    </h3>
                                </b-col>

                                <b-col class="text-right">
                                    <!-- EXCEL - BUTTONS -->
                                    <transition name="slide-fade-icons">
                                    <b-button 
                                        v-show="showExcel"
                                        variant="schedule-english-2"
                                        size="sm" @click="reloadIframe">
                                        <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                                    </b-button>
                                    </transition>

                                    <transition name="slide-fade-icons">
                                    <b-button 
                                        v-show="showExcel"
                                        variant="schedule-english-2"
                                        @click="showExcel = !showExcel"
                                        size="sm">
                                        <b-icon icon="x" scale="1.3"></b-icon>
                                    </b-button> 
                                    </transition>

                                    <!-- JOBO.FERR - BUTTONS -->
                                    <b-button
                                        v-show="!showExcel && programSeasonSponsor.show_excel == 'si'"
                                        :disabled="programJobFieldsLoading || loadingTableData || programJobFieldsLoading"
                                        variant="schedule-english-2 "
                                        size="sm" @click="showExcel = !showExcel">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                                            <path d="M6 12v-2h3v2H6z"/>
                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z"/>
                                        </svg>
                                    </b-button>

                                    <transition name="slide-fade-icons">
                                    <b-button variant="primary" size="sm"
                                        :disabled="programJobFieldsLoading || loadingTableData || programJobFieldsLoading"
                                        v-show="!showExcel"
                                        @click="reloadData">
                                        <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                                    </b-button>
                                    </transition>
                                </b-col>
                            </b-row>
                        </b-card-header>    

                        <!-- loadingTableData -->
                        <div style="height:300px;" v-show="loadingTableData && !showExcel" >
                            <pulse-loader
                                :color="'#5e72e4'"
                                :margin="'10px'"
                                :size="20"
                                style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                        </div>
                        <div style="height:65vh" v-show="showExcel && programSeasonSponsorLoading" >
                            <pulse-loader
                                :color="'#5e72e4'"
                                :margin="'10px'"
                                :size="20"
                                style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                        </div>

                        <!-- table -->
                        <transition name="slide-fade">
                        <div class="table-responsive" v-show="!loadingTableData && !showExcel">
                            <table class="table tablesorter align-items-center table-flush"
                            >
                                <thead class="thead-light">
                                    <tr>
                                        <th>#</th>
                                        <th v-for="(field, i) in programJobFieldsSorted" :key="`${i}-field-th-sorted`">
                                            {{ field.attributes.field_name }}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody class="list">
                                    <tr
                                        v-for="(row, index) in tableData" :key="`${index}-tabledata`"
                                        class="job-offer"
                                        @click="openModalAttempt(row)"
                                    >
                                        <td>{{ index + 1 }}</td>

                                        <td  v-for="(order, j) in input_order" :key="`${j}-order`">
                                            <span
                                                v-for="(field, k) in row.attributes.jobFields" :key="`${k}-field-td`"
                                                v-show="order==field.attributes.programJobField_id"
                                            >
                                                {{ field.attributes.field_value }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </transition>

                        <!-- google drive excel -->
                        <transition name="slide-fade">
                        <b-card no-body style="height: 65vh" v-if="!loadingTableData && showExcel && !programSeasonSponsorLoading">
                            <iframe
                                id="drive_iframe"
                                name="drive_iframe"
                                :src="`https://docs.google.com/spreadsheets/d/${programSeasonSponsor.excel_identifier}/htmlembed/sheet?gid=${programSeasonSponsor.gid}`" 
                                width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" ></iframe>
                        </b-card>
                        </transition>
                        <!-- end google drive -->
                    </b-card>
                </b-col>

                <!-- 
                    modal attempt job
                -->
                <b-modal
                    id="modal-attempt-job"
                    centered
                    hide-footer
                    size="lg"
                    no-close-on-backdrop
                    no-close-on-esc
                    header-class="pb-0"
                >
                    <!-- modal header -->
                    <template #modal-header class="pb-0">
                        <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                            <b-row align-h="between" align-v="center">
                                <b-col cols="auto"><h3 class="text-primary mb-0 text-uppercase">Oferta laboral</h3></b-col>
                                <b-col cols="auto">
                                    <b-button size="sm" variant="magenta" @click="closeModalAttempt"
                                        :disabled="attemptLoading"
                                    ><b-icon icon="x" scale="1.5"/>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </template>

                    <!-- modal content -->
                    <template #default>
                        <b-container>
                            <b-row>
                                <!-- job offer title -->
                                <b-col cols="12" style="overflow:auto">
                                    <b-list-group class="d-flex justify-content-center" style="min-width:500px">
                                        <b-list-group-item  variant="primary" class="py-2">
                                            <b-row>
                                                <b-col v-for="(field, i) in programJobFieldsTitleOrderSorted" :key="`${i}-pjf`">
                                                    <p class="m-0 p-0 text-lowercase text-black" style="font-weight:bold;letter-spacing:2px;">
                                                        {{ field.attributes.field_name }}
                                                    </p>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                        <b-list-group-item class="py-2">
                                            <b-row>
                                                <b-col v-for="(order, i) in job_title_order" :key="`${i}-jto`">
                                                    <p class="m-0 p-0 text-lowercase" style="letter-spacing:1px"
                                                        v-for="(field, i) in selectedProgramSponsorJob.attributes.jobFields" :key="`${i}-spsj`"
                                                        v-show="order == field.attributes.programJobField_id"
                                                    >{{ field.attributes.field_value }}</p>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-col>

                                <!--  -->
                                <b-col>
                                    <b-row>
                                        <b-col cols="12" v-show="participantLoading">
                                            <div style="height:100px;">
                                                <pulse-loader
                                                    :color="'#5e72e4'"
                                                    :margin="'10px'"
                                                    :size="20"
                                                    class="text-center"
                                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                                            </div>
                                        </b-col>

                                        <!-- message postulation request error-->
                                        <b-col cols="12" v-show="!participantLoading && postulationErrorMessage != ''" class="mt-4">
                                            <b-alert show variant="magenta">{{ postulationErrorMessage }}</b-alert>
                                        </b-col>

                                        <!-- message documents not presented-->
                                        <b-col cols="12" v-show="!participantLoading && programSeasonParticipant.vouchers_date_limit_status == 'no presentado'" class="mt-4">
                                            <b-alert show variant="magenta">
                                                Para poder postular a una oferta laboral tienes que haber presentado los vouchers de <b>FECHA LÍMITE</b> : <b>28/06/2022</b>.
                                                <br>
                                                <br>
                                                PENDIENTES:
                                                <br>
                                                <ul>
                                                    <li v-for="(voucher_limit, i) in programSeasonParticipant.vouchers_date_limit" :key="`${i}-limit_Date`">{{ voucher_limit }}</li>
                                                </ul>
                                            </b-alert>
                                        </b-col>

                                        <!-- message documents not presented-->
                                        <b-col cols="12" v-show="!participantLoading && programSeasonParticipant.doc_group_one_status == 'no presentado'" class="mt-4">
                                            <b-alert show variant="magenta">
                                                Para poder postular a una oferta laboral tienes que presentar todos los <b>DOCUMENTOS</b> del grupo <b>UNO</b>.
                                            </b-alert>
                                        </b-col>

                                        <!-- message participant information  not filled-->
                                        <b-col cols="12" v-show="!participantLoading && programSeasonParticipant.information_status == 'no rellenado'" class="mt-4">
                                            <b-alert show variant="magenta">
                                                Para poder postular a una oferta laboral tienes que rellenar la <b>FICHA DE REGISTRO</b>.
                                            </b-alert>
                                        </b-col>

                                        <!-- message immigration_appointment_date < 30 DE SEPTIEMBRE-->
                                        <b-col cols="12" v-show="!participantLoading && (programSeasonParticipant.immigration_appointment_date == 'have_passport' ? false : (programSeasonParticipant.immigration_appointment_date > '2023-10-30'))" class="mt-4">
                                            <b-alert show variant="magenta">
                                                Para poder postular a una oferta laboral la fecha de cita con migraciones debe ser <b>ANTES DEL 30 DE SEPTIEMBRE</b>. (Tu cita registrada es:  {{programSeasonParticipant.immigration_appointment_date}})
                                            </b-alert>
                                        </b-col>

                                        <!-- message same sponsor-->
                                        <b-col cols="12" v-show="!participantLoading && showMessageSameSponsor == 'si'" class="mt-4">
                                            <b-alert show variant="magenta">
                                                Solo puedes postular a las ofertas laborales de un mismo <b>SPONSOR.</b>
                                            </b-alert>
                                        </b-col>

                                        <!-- attempt -->
                                        <b-col cols="12" v-show="!participantLoading && programSeasonParticipant.doc_group_one_status == 'presentado' && !(showMessageSameSponsor == 'si') && programSeasonParticipant.information_status == 'rellenado' && (programSeasonParticipant.immigration_appointment_date == 'have_passport' ? true : (programSeasonParticipant.immigration_appointment_date < '2023-10-30')) && programSeasonParticipant.vouchers_date_limit_status == 'presentado'" class="mt-4">
                                            <b-card title="Seleccionar como : ">
                                                <!-- messages -->
                                                <b-row>
                                                    <b-col cols="12" v-show="showMessageMaxPostulations == 'si'">
                                                        <b-alert show variant="primary">
                                                            Ya realizaste el número de postulaciones máximas.
                                                        </b-alert>
                                                    </b-col>

                                                    <b-col cols="12" v-show="showMessageOfferAlreadyAttemppt == 'si' && showMessageMaxPostulations == 'no'">
                                                        <b-alert show variant="primary">
                                                            Ya escogiste esta oferta laboral.
                                                        </b-alert>
                                                    </b-col>
                                                </b-row>

                                                <!-- buttons -->
                                                <b-row align-h="around" align-v="center" v-show="!(showMessageOfferAlreadyAttemppt == 'si')">
                                                    <b-col v-show="programSeasonParticipant.first_attempt == 'si' && programSeasonParticipant.first_attempt_button_show != 'no'" cols="auto">
                                                        <pulse-loader
                                                            :loading="programSeasonParticipant.first_attemptLoading || false"
                                                            :color="'#5e72e4'"
                                                            :margin="'2px'"
                                                            :size="8"
                                                            class="pt-2 pb-1"
                                                        ></pulse-loader>

                                                        <b-button
                                                            v-show="!programSeasonParticipant.first_attemptLoading" :disabled="attemptLoading"
                                                            variant="outline-primary" @click="haveFilledForm('Primera opción')">
                                                            Primera opción de trabajo
                                                        </b-button>
                                                    </b-col>

                                                    <b-col v-show="programSeasonParticipant.second_attempt == 'si' && programSeasonParticipant.second_attempt_button_show != 'no'" cols="auto">
                                                        <pulse-loader
                                                            :loading="programSeasonParticipant.second_attemptLoading || false"
                                                            :color="'#5e72e4'"
                                                            :margin="'2px'"
                                                            :size="8"
                                                            class="pt-2 pb-1"
                                                        ></pulse-loader>

                                                        <b-button
                                                            v-show="!programSeasonParticipant.second_attemptLoading" :disabled="attemptLoading || postulations.length == 0"
                                                            :variant="postulations.length == 0 ? 'outline-ligth' : 'outline-primary'"
                                                            @click="haveFilledForm('Segunda opción')">
                                                            Segunda opción de trabajo
                                                        </b-button>
                                                    </b-col>

                                                    <b-col v-show="programSeasonParticipant.third_attempt == 'si' && programSeasonParticipant.third_attempt_button_show != 'no'" cols="auto">
                                                        <pulse-loader
                                                            :loading="programSeasonParticipant.third_attemptLoading || false"
                                                            :color="'#5e72e4'"
                                                            :margin="'2px'"
                                                            :size="8"
                                                            class="pt-2 pb-1"
                                                        ></pulse-loader>

                                                        <b-button
                                                            v-show="!programSeasonParticipant.third_attemptLoading" :disabled="attemptLoading || postulations.length <= 1"
                                                            :variant="postulations.length <= 1 ? 'outline-ligth' : 'outline-primary'"
                                                            @click="haveFilledForm('Tercera opción')">
                                                            Tercera opción de trabajo
                                                        </b-button>
                                                    </b-col>

                                                    <b-col v-show="programSeasonParticipant.fourth_attempt == 'si' && programSeasonParticipant.fourth_attempt_button_show != 'no'" cols="auto">
                                                        <pulse-loader
                                                            :loading="programSeasonParticipant.fourth_attemptLoading || false"
                                                            :color="'#5e72e4'"
                                                            :margin="'2px'"
                                                            :size="8"
                                                            class="pt-2 pb-1"
                                                        ></pulse-loader>

                                                        <b-button
                                                            v-show="!programSeasonParticipant.fourth_attemptLoading" :disabled="attemptLoading || postulations.length <= 2"
                                                            :variant="postulations.length <= 2 ? 'outline-ligth' : 'outline-primary'"
                                                            @click="haveFilledForm('Cuarta opción')">
                                                            Cuarta opción de trabajo
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </b-col>

                                        <!-- postulations -->
                                        <b-col cols="12"
                                            v-show="!participantLoading && programSeasonParticipant.doc_group_one_status == 'presentado' && postulations.length != 0 && !(showMessageSameSponsor == 'si')"
                                        class="mt-4" style="overflow:auto"
                                        >
                                            <b-card title="Postulaciones : " style="min-width:500px">
                                                <b-list-group class="d-flex justify-content-center"  style="width:100%">
                                                    <b-list-group-item  variant="green" class="py-2">
                                                        <b-row>
                                                            <b-col class="text-center">#</b-col>
                                                            <b-col v-for="(field, i) in programJobFieldsTitleOrderSorted" :key="`${i}-pjf`">
                                                                <p class="m-0 p-0 text-lowercase text-black" style="font-weight:bold;letter-spacing:2px;">
                                                                    {{ field.attributes.field_name }}
                                                                </p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-list-group-item>

                                                    <b-list-group-item class="py-2"
                                                        v-for="(postulation, i) in postulations" :key="`${i}-postulations`"
                                                    >
                                                        <b-row>
                                                            <b-col>{{ postulation.attributes.subject }}</b-col>
                                                            <b-col v-for="(order, i) in job_title_order" :key="`${i}-jto`">
                                                                <p class="m-0 p-0 text-lowercase" style="letter-spacing:1px;"
                                                                    v-for="(field, i) in postulation.attributes.programSponsorJob.attributes.jobFields" :key="`${i}-spsj-2`"
                                                                    v-show="order == field.attributes.programJobField_id"
                                                                >{{ field.attributes.field_value }}</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-list-group-item>
                                            </b-list-group>
                                            </b-card>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                
                            </b-row>
                        </b-container>
                    </template>
                </b-modal>
                <!-- end modal attemp job -->

                <!-- 
                    modal postulant information
                -->
                <b-modal
                    id="modal-postulation-information"
                    centered
                    hide-footer
                    size="md"
                    no-close-on-backdrop
                    no-close-on-esc
                    header-class="pb-0"
                >
                    <!-- modal header -->
                    <template #modal-header class="pb-0">
                        <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                            <b-row align-h="between" align-v="center">
                                <b-col cols="auto"><h3 class="text-primary mb-0 text-uppercase">Registro</h3></b-col>
                                <b-col cols="auto">
                                    <b-button size="sm" variant="magenta" @click="closeModalPostulationInformation" :disabled="fillLoading"><b-icon icon="x" scale="1.5"/></b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </template>

                    <!-- modal content -->
                    <template #default>
                        <b-container>
                            <b-row>
                                <b-col>
                                    <b-form @submit.prevent="fillPostulationInformation">
                                        <b-form-row>
                                            <!-- start_date -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Fecha de inicio * (AÑO-MES-DÍA). "
                                                    description="Tomar en cuenta las fechas listadas en la descripción del empleo. Cualquier variación por parte del SPONSOR O EMPLEADOR se les hará saber. (Acorde al inicio de vacaciones de tu Universidad)"
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-input-group v-if="!fillLoading">
                                                        <b-form-input v-model="start_date" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                                        <b-input-group-append>
                                                            <b-form-datepicker
                                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                selected-variant="primary"
                                                                today-variant="naranja"
                                                                locale="es"

                                                                today-button
                                                                label-today-button="today"
                                                                today-button-variant="outline-naranja"
                                                                
                                                                reset-button
                                                                label-reset-button="reset"
                                                                reset-button-variant="outline-magenta"

                                                                close-button
                                                                label-close-button="ok"
                                                                close-button-variant="primary"

                                                                v-model="start_date"
                                                                :state="showInputStatus('start_date')"
                                                            ></b-form-datepicker>
                                                        </b-input-group-append>
                                                    </b-input-group>


                                                    <b-form-invalid-feedback
                                                        :state="showInputStatus('start_date')"
                                                        v-for="(inputError, i) in showInputErrors('start_date')"
                                                        :key="`${i}-ficha-start_date`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- end_date -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Fecha de término * (AÑO-MES-DÍA)."
                                                    description="Esta fecha estará alineada al inicio de clase de tu universidad o instituto. Fecha máximo de trabajo 31 de Marzo"
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-input-group v-if="!fillLoading">
                                                        <b-form-input v-model="end_date" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                                        <b-input-group-append>
                                                            <b-form-datepicker
                                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                selected-variant="primary"
                                                                today-variant="naranja"
                                                                locale="es"

                                                                today-button
                                                                label-today-button="today"
                                                                today-button-variant="outline-naranja"
                                                                
                                                                reset-button
                                                                label-reset-button="reset"
                                                                reset-button-variant="outline-magenta"

                                                                close-button
                                                                label-close-button="ok"
                                                                close-button-variant="primary"

                                                                v-model="end_date"
                                                                :state="showInputStatus('end_date')"
                                                            ></b-form-datepicker>
                                                        </b-input-group-append>
                                                    </b-input-group>


                                                    <b-form-invalid-feedback
                                                        :state="showInputStatus('end_date')"
                                                        v-for="(inputError, i) in showInputErrors('end_date')"
                                                        :key="`${i}-ficha-end_date`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- gender -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Género *"
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-radio-group
                                                        :options="[{ value: 'male', text: 'Male' },{ value: 'female', text: 'Female' },]"
                                                        v-model="gender"
                                                        :state="showInputStatus('gender')"
                                                        v-show="!fillLoading"
                                                        stacked
                                                        plain
                                                    ></b-form-radio-group>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('gender')"
                                                        :key="`${i}-ficha-gender`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- country -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="País *"
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-input
                                                        placeholder="Ingrese el nombre se su país."
                                                        type="text"
                                                        v-model="country"
                                                        :state="showInputStatus('country')"
                                                        v-show="!fillLoading"
                                                    ></b-form-input>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('country')"
                                                        :key="`${i}-ficha-country`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- english_level -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Nivel de inglés *"
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-radio-group
                                                        :options="[{ value: 'basico', text: 'Básico' },{ value: 'medio', text: 'Medio' },{ value: 'avanzado', text: 'Avanzado' }]"
                                                        v-model="english_level"
                                                        :state="showInputStatus('english_level')"
                                                        v-show="!fillLoading"
                                                        stacked
                                                        plain
                                                    ></b-form-radio-group>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('english_level')"
                                                        :key="`${i}-ficha-english_level`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- fellow_traveler -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="¿Viajas con alguien? *"
                                                    description="Colocar los apellidos y nombres de tu amigo(a) con quien te gustaría ir."
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-input
                                                        placeholder="Ingrese los apellidos y nombres."
                                                        type="text"
                                                        v-model="fellow_traveler"
                                                        :state="showInputStatus('fellow_traveler')"
                                                        v-show="!fillLoading"
                                                    ></b-form-input>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('fellow_traveler')"
                                                        :key="`${i}-ficha-fellow_traveler`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- first_middle_last_name -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Full name *"
                                                    description="(first, middle and last name)"
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-input
                                                        type="text"
                                                        v-model="first_middle_last_name"
                                                        :state="showInputStatus('first_middle_last_name')"
                                                        v-show="!fillLoading"
                                                    ></b-form-input>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('first_middle_last_name')"
                                                        :key="`${i}-ficha-first_middle_last_name`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- have_medical_problems -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Do you have any medical problems? *"
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-radio-group
                                                        :options="[{ value: 'yes', text: 'Yes' },{ value: 'no', text: 'No' },]"
                                                        v-model="have_medical_problems"
                                                        :state="showInputStatus('have_medical_problems')"
                                                        v-show="!fillLoading"
                                                        stacked
                                                        plain
                                                    ></b-form-radio-group>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('have_medical_problems')"
                                                        :key="`${i}-ficha-have_medical_problems`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- explain_medical_problems -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="If was Yes, explain *"
                                                    description="(If was not just set N/A)"
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-input
                                                        type="text"
                                                        v-model="explain_medical_problems"
                                                        :state="showInputStatus('explain_medical_problems')"
                                                        v-show="!fillLoading"
                                                    ></b-form-input>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('explain_medical_problems')"
                                                        :key="`${i}-ficha-explain_medical_problems`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- have_ever_been_convicted -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Have you ever been convicted of a crime? "
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-radio-group
                                                        :options="[{ value: 'yes', text: 'Yes' },{ value: 'no', text: 'No' },]"
                                                        v-model="have_ever_been_convicted"
                                                        :state="showInputStatus('have_ever_been_convicted')"
                                                        v-show="!fillLoading"
                                                        stacked
                                                        plain
                                                    ></b-form-radio-group>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('have_ever_been_convicted')"
                                                        :key="`${i}-ficha-have_ever_been_convicted`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- explain_ever_been_convicted -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="If was Yes, explain *"
                                                    description="(If was not just set N/A)"
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-input
                                                        type="text"
                                                        v-model="explain_ever_been_convicted"
                                                        :state="showInputStatus('explain_ever_been_convicted')"
                                                        v-show="!fillLoading"
                                                    ></b-form-input>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('explain_ever_been_convicted')"
                                                        :key="`${i}-ficha-explain_ever_been_convicted`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                            
                                            <!-- have_ever_participated_wat_Program -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Have you ever participated in the J-1 Work/Travel Program? *"
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-radio-group
                                                        :options="[{ value: 'yes', text: 'Yes' },{ value: 'no', text: 'No' },]"
                                                        v-model="have_ever_participated_wat_Program"
                                                        :state="showInputStatus('have_ever_participated_wat_Program')"
                                                        v-show="!fillLoading"
                                                        stacked
                                                        plain
                                                    ></b-form-radio-group>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('have_ever_participated_wat_Program')"
                                                        :key="`${i}-ficha-have_ever_participated_wat_Program`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- have_ever_been_denied_visa_by_embassy -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Have you ever been denied a visa by a U.S. Embassy? "
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-radio-group
                                                        :options="[{ value: 'yes', text: 'Yes' },{ value: 'no', text: 'No' },]"
                                                        v-model="have_ever_been_denied_visa_by_embassy"
                                                        :state="showInputStatus('have_ever_been_denied_visa_by_embassy')"
                                                        v-show="!fillLoading"
                                                        stacked
                                                        plain
                                                    ></b-form-radio-group>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('have_ever_been_denied_visa_by_embassy')"
                                                        :key="`${i}-ficha-have_ever_been_denied_visa_by_embassy`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- how_find_about_wat_program -->
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="How did you find out about this J-1 Work/Travel Program? *"
                                                >
                                                    <pulse-loader
                                                        :loading="fillLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    ></pulse-loader>

                                                    <b-form-input
                                                        type="text"
                                                        v-model="how_find_about_wat_program"
                                                        :state="showInputStatus('how_find_about_wat_program')"
                                                        v-show="!fillLoading"
                                                    ></b-form-input>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('how_find_about_wat_program')"
                                                        :key="`${i}-ficha-how_find_about_wat_program`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                        </b-form-row>

                                        <b-row align-h="center">
                                            <b-col cols="auto">
                                                <b-button variant="primary" type="submit" size="lg" :disabled="fillLoading">REGISTRAR</b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </b-col>
                            </b-row>
                        </b-container>
                    </template>
                </b-modal>
                <!-- end modal postulant information -->
            </b-row>
        </b-col>
    </b-row>

</template>

<script>
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
// programSeasonSponsor
import { getProgramSeasonSponsor } from '@/api/ProgramSeasonSponsor/ProgramSeasonSponsor'
// programSeasonSponsor.programSponsorJobs
import { getProgramSeasonSponsorProgramSponsorJobs} from '@/api/ProgramSeasonSponsor/ProgramSponsorJob';
// programSeasonSponsor.programJobField
import { getProgramSeasonSponsorProgramJobFields } from '@/api/ProgramSeasonSponsor/ProgramJobField';
// programSeasonParticipant
import { getProgramSeasonParticipant } from '@/api/ProgramSeasonParticipant/ProgramSeasonParticipant';
//
import { storePostulation } from '@/api/Postulation/Postulation';
// 
import { storePostulationInformation } from '@/api/PostulationInformation/PostulationInformation';
//
import swal from 'sweetalert'

export default {
    name: 'SponsorJobOffer',
    components: { 
        IntranetNavContentLinkExact,
    },
    data: () => ({
        tableData: [],
        loadingTableData: false,
        //
        programJobFields: [],
        programJobFieldsSorted: [],
        programJobFieldsTitleOrderSorted: [],
        programJobFieldsLoading: false,
        //
        input_order: [],
        job_title_order: [],
        //
        attemptLoading: false,
        //
        programSeasonSponsor: {},
        postulations: [],
        programSeasonSponsorLoading: false,
        //
        showExcel: false,
        /* modal attempt job */
        selectedProgramSponsorJob: {},
        programSeasonParticipant: {},
        participantLoading: false,
        /* modal postulation information */
        current_subject: '',
        //
        id_current_program_season_sponsor: '',
        start_date: '',
        end_date: '',
        gender: '',
        country: '',
        english_level: '',
        fellow_traveler: '',
        first_middle_last_name: '',
        have_medical_problems: '',
        explain_medical_problems: '',
        have_ever_been_convicted: '',
        explain_ever_been_convicted: '',
        have_ever_participated_wat_Program: '',
        have_ever_been_denied_visa_by_embassy: '',
        how_find_about_wat_program: '',
        //
        inputErrors: {},
        initialInputValues: true,
        fillLoading: false,
        //
        postulationErrorMessage: '',
        // isAlredyOpen: 0,
        // localstorageRouteName: '',
        // localstorageRouteNameAlreadyOpen: 0
    }),
    created() {
        // this.checkRoute()
        this.geData()
    },
    // beforeDestroy() {
    //     if (localStorage.getItem(this.localstorageRouteNameAlreadyOpen) && this.$router.currentRoute.name != 'only-one-page') {
    //         localStorage.removeItem(this.localstorageRouteName)
    //         localStorage.removeItem(this.localstorageRouteNameAlreadyOpen)
    //     }   
    // },
    computed: {
        showMessageMaxPostulations () {
            let numberOfAllowedPostulations = 0
            if (this.programSeasonParticipant.first_attempt == 'si') numberOfAllowedPostulations += 1
            if (this.programSeasonParticipant.second_attempt == 'si') numberOfAllowedPostulations += 1
            if (this.programSeasonParticipant.third_attempt == 'si') numberOfAllowedPostulations += 1
            if (this.programSeasonParticipant.fourth_attempt == 'si') numberOfAllowedPostulations += 1

            if (this.postulations.length == numberOfAllowedPostulations) return 'si'
            else return 'no'
        },
        showMessageOfferAlreadyAttemppt () {
            let offerAlreadyRegistered = 'no'

            if (this.postulations.length != 0) {
                for (const postulation of this.postulations) {
                    if (postulation.attributes.programSponsorJob.attributes.id == this.selectedProgramSponsorJob.attributes.id) {
                        offerAlreadyRegistered = 'si'
                    }
                }
            }

            if (offerAlreadyRegistered == 'si') return 'si'
            else return 'no'
        },
        showMessageSameSponsor () {
            let sameSponsor = 'si'
            let fueRechazado = 'no'
            let estadoPendiente = 'no'
 
            if (this.postulations.length != 0) {
                this.postulations.forEach(postulation => {
                    if (postulation.attributes.status == 'rechazado') fueRechazado = 'si'
                    if (postulation.attributes.status == 'pendiente') estadoPendiente = 'si'
                })

                if (fueRechazado == 'no' && estadoPendiente == 'no') {
                    if (this.postulations[0].attributes.programSeasonSponsor_id == this.$route.params.IdProgramSeasonSponsor) {
                        sameSponsor == 'si'
                    } else {
                        sameSponsor = 'no'
                    }
                } else {
                    sameSponsor = 'si'
                }

            }

            if (sameSponsor == 'si') return 'no'
            else return 'si'
        }
    },
    methods: {
        // checkRoute () {
        //     this.localstorageRouteName = `token-sponsor-${this.$route.params.IdProgramSeasonSponsor}-jobs`
        //     this.localstorageRouteNameAlreadyOpen = `sponsor-${this.$route.params.IdProgramSeasonSponsor}-jobs-is-alredy-open`

        //     localStorage.setItem(this.localstorageRouteName, window.location.href)

        //     let self = this

        //     window.onbeforeunload = function() {
        //         if(localStorage.getItem(self.localstorageRouteName) && localStorage.getItem(self.localstorageRouteName) == window.location.href) {
        //             localStorage.removeItem(self.localstorageRouteName)
        //             localStorage.removeItem(self.localstorageRouteNameAlreadyOpen)
        //         }
        //     }
            
        //     if (localStorage.getItem(this.localstorageRouteNameAlreadyOpen)) {
        //         this.isAlredyOpen = parseInt(localStorage.getItem(this.localstorageRouteNameAlreadyOpen))

        //         if (this.isAlredyOpen + 1  > 1) {
        //             this.$router.push({name: 'only-one-page'})
        //         }

        //     } else {
        //         this.isAlredyOpen += 1
        //         localStorage.setItem(this.localstorageRouteNameAlreadyOpen, this.isAlredyOpen )
        //     }
        // },
        geData () {
            this.programJobFieldsLoading = true
            this.loadingTableData = true
            this.programSeasonSponsorLoading = true

            let IdProgramSeasonSponsor = this.$route.params.IdProgramSeasonSponsor  // ok

            getProgramSeasonSponsorProgramJobFields(IdProgramSeasonSponsor)
                .then(({ data }) => {
                    this.programJobFields = data.data.map(el => ({...el, 'value': ''}))

                    getProgramSeasonSponsorProgramSponsorJobs(IdProgramSeasonSponsor)
                        .then(({ data }) => {
                            this.tableData = data.data.filter(el => el.attributes.status == 'activado')

                            getProgramSeasonSponsor(IdProgramSeasonSponsor)
                                .then(({ data }) => {
                                    this.programSeasonSponsor = data.data.attributes
                                    this.input_order = this.programSeasonSponsor.input_order.split('|')
                                    this.job_title_order = this.programSeasonSponsor.job_title_order.split('|')

                                    // ordenando programJobField
                                    this.programJobFieldsSorted = []
                                    for (const order of this.input_order) {
                                        for (const programJobField of this.programJobFields) {
                                            if (order == programJobField.id) {
                                                this.programJobFieldsSorted.push(JSON.parse(JSON.stringify(programJobField)))
                                                break
                                            }
                                        }
                                    }

                                    // ordenando programJobField - title order
                                    this.programJobFieldsTitleOrderSorted = []
                                    for (const title_order of this.job_title_order) {
                                        for (const programJobField of this.programJobFields) {
                                            if (title_order == programJobField.id) {
                                                this.programJobFieldsTitleOrderSorted.push(JSON.parse(JSON.stringify(programJobField)))
                                                break
                                            }
                                        }
                                    }
                                
                                })
                                .finally(() => {
                                    this.programSeasonSponsorLoading = false
                                })
                        })
                        .finally(() => {
                            this.loadingTableData = false
                        })
                })
                .finally(() => {
                    this.programJobFieldsLoading = false
                })
        },

        reloadIframe() {
            this.programSeasonSponsorLoading = true

            var iframe = document.getElementById('drive_iframe');
            var tmp_src = iframe.src;
            iframe.src = '';
            iframe.src = tmp_src;

            setTimeout(() => {
                this.programSeasonSponsorLoading = false
            }, 1000)
        },

        getJobsWithoutLoading () {
            getProgramSeasonSponsorProgramSponsorJobs(this.$route.params.IdProgramSeasonSponsor)
                .then(({ data }) => {
                    this.tableData = data.data
                })
        },

        // Modal attempt job
        openModalAttempt (pProgramSponsorJob) {
            this.clearInputsAndStates()
            this.getAttempts()
            this.selectedProgramSponsorJob = JSON.parse(JSON.stringify(pProgramSponsorJob))
            this.$bvModal.show('modal-attempt-job')
        },
        closeModalAttempt () {
            if (this.postulationErrorMessage != '') this.reloadData()
            this.$bvModal.hide('modal-attempt-job')
            this.clearInputsAndStates()
        },

        clearInputsAndStates () {
            this.selectedProgramSponsorJob = {
                attributes: {}
            }
            this.programSeasonParticipant = {}
            //
            this.postulationErrorMessage = ''
        },

        getAttempts () {
            this.participantLoading = true
            getProgramSeasonParticipant(this.$route.params.IdProgramSeasonParticipant)
                .then(({ data }) => {
                    // code A
                    this.programSeasonParticipant = data.data.attributes
                    this.postulations = this.programSeasonParticipant.postulations
                    for (const postulation of this.postulations) {
                        switch (postulation.attributes.subject) {
                            case 'Primera opción':
                                this.$set(this.programSeasonParticipant, 'first_attempt_button_show', 'no')
                                break;
                            case 'Segunda opción':
                                this.$set(this.programSeasonParticipant, 'second_attempt_button_show', 'no')
                                break;
                            case 'Tercera opción':
                                this.$set(this.programSeasonParticipant, 'third_attempt_button_show', 'no')
                                break;
                            case 'Cuarta opción':
                                this.$set(this.programSeasonParticipant, 'fourth_attempt_button_show', 'no')
                                break;
                            default:
                                break;
                        }
                    }
                    // end code A
                })
                .finally(() => {
                    this.participantLoading = false
                })
        },

        haveFilledForm(pSubject) {
            if (this.programSeasonParticipant.postulationInformation) this.attempt(pSubject)
            else this.openModalPostulationInformation(pSubject)
        },

        // Modal postulation information
        openModalPostulationInformation (pSubject) {
            this.clearFormInputsAndStates()
            this.current_subject = pSubject
            this.$bvModal.show('modal-postulation-information')
        },
        closeModalPostulationInformation () {
            this.$bvModal.hide('modal-postulation-information')
            this.clearFormInputsAndStates()
        },

        fillPostulationInformation(){
            this.fillLoading = true
            this.initialInputValues = false
            this.inputErrors = {}
            
            let InfoForm = new FormData()
            InfoForm.append('programSeasonParticipant_id', this.programSeasonParticipant.id)
            InfoForm.append('id_current_program_season_sponsor', this.programSeasonSponsor.id)
            InfoForm.append('start_date', this.start_date)
            InfoForm.append('end_date', this.end_date)
            InfoForm.append('gender', this.gender)
            InfoForm.append('country', this.country)
            InfoForm.append('english_level', this.english_level)
            InfoForm.append('fellow_traveler', this.fellow_traveler)
            InfoForm.append('first_middle_last_name', this.first_middle_last_name)
            InfoForm.append('have_medical_problems', this.have_medical_problems)
            InfoForm.append('explain_medical_problems', this.explain_medical_problems)
            InfoForm.append('have_ever_been_convicted', this.have_ever_been_convicted)
            InfoForm.append('explain_ever_been_convicted', this.explain_ever_been_convicted)
            InfoForm.append('have_ever_participated_wat_Program', this.have_ever_participated_wat_Program)
            InfoForm.append('have_ever_been_denied_visa_by_embassy', this.have_ever_been_denied_visa_by_embassy)
            InfoForm.append('how_find_about_wat_program', this.how_find_about_wat_program)
            InfoForm.append('status', 'registrado')

            storePostulationInformation(InfoForm)
                .then(() => {
                    swal('Rellenado correcto!', 'Información guardada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.attempt(this.current_subject)
                                    this.closeModalPostulationInformation()
                                    break;

                                default:
                                    console.log('editDocument--swal-break');
                                    break;
                            }
                        })
                })
                .catch((err) => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.fillLoading = false
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearFormInputsAndStates() {
            this.current_subject = ''
            //
            this.id_current_program_season_sponsor = ''
            this.start_date = ''
            this.end_date = ''
            this.gender = ''
            this.country = 'Perú'
            this.english_level = ''
            this.fellow_traveler = ''
            this.first_middle_last_name = `${this.programSeasonParticipant.user_name} ${this.programSeasonParticipant.user_last_name}`
            this.have_medical_problems = ''
            this.explain_medical_problems = ''
            this.have_ever_been_convicted = ''
            this.explain_ever_been_convicted = ''
            this.have_ever_participated_wat_Program = ''
            this.have_ever_been_denied_visa_by_embassy = ''
            this.how_find_about_wat_program = ''

            this.initialInputValues = true
            this.inputErrors = {}

            this.postulationErrorMessage = ''
        },


        attempt (pSubject) {
            this.attemptLoading = true
            this.postulationErrorMessage = ''
            switch (pSubject) {
                case 'Primera opción':
                    this.$set(this.programSeasonParticipant, 'first_attemptLoading', true)
                    break;
                case 'Segunda opción':
                    this.$set(this.programSeasonParticipant, 'second_attemptLoading', true)
                    break;
                case 'Tercera opción':
                    this.$set(this.programSeasonParticipant, 'third_attemptLoading', true)
                    break;
                case 'Cuarta opción':
                    this.$set(this.programSeasonParticipant, 'fourth_attemptLoading', true)
                    break;
                default:
                    break;
            }

            let AttemptForm = new FormData()
            AttemptForm.append('programSeasonParticipant_id', this.programSeasonParticipant.id)
            AttemptForm.append('programSponsorJob_id', this.selectedProgramSponsorJob.id)
            AttemptForm.append('subject', pSubject)

            storePostulation(AttemptForm)
                .then(() => {
                    this.participantLoading = true
                    getProgramSeasonParticipant(this.$route.params.IdProgramSeasonParticipant)
                        .then(({ data }) => {
                            // code A
                            this.programSeasonParticipant = data.data.attributes
                            this.postulations = this.programSeasonParticipant.postulations
                            for (const postulation of this.postulations) {
                                switch (postulation.attributes.subject) {
                                    case 'Primera opción':
                                        this.$set(this.programSeasonParticipant, 'first_attempt_button_show', 'no')
                                        break;
                                    case 'Segunda opción':
                                        this.$set(this.programSeasonParticipant, 'second_attempt_button_show', 'no')
                                        break;
                                    case 'Tercera opción':
                                        this.$set(this.programSeasonParticipant, 'third_attempt_button_show', 'no')
                                        break;
                                    case 'Cuarta opción':
                                        this.$set(this.programSeasonParticipant, 'fourth_attempt_button_show', 'no')
                                        break;
                                    default:
                                        break;
                                }
                            }
                            // end code A
                        })
                        .finally(() => {
                            this.participantLoading = false
                        })
                })
                .catch(err => {
                    if (err.response) {
                        if (err.response.status == 404) this.postulationErrorMessage = err.response.data.message
                    }
                })
                .finally(() => {
                    switch (pSubject) {
                        case 'Primera opción':
                            this.programSeasonParticipant.first_attemptLoading = false
                            break;
                        case 'Segunda opción':
                            this.programSeasonParticipant.second_attemptLoading = false
                            break;
                        case 'Tercera opción':
                            this.programSeasonParticipant.third_attemptLoading = false
                            break;
                        case 'Cuarta opción':
                            this.programSeasonParticipant.fourth_attemptLoading = false
                            break;
                        default:
                            break;
                        }
                    this.attemptLoading = false
                })
        },



        reloadData () {
            this.geData()
        },
    }
};
</script>

<style scoped>
.job-offer {
    cursor: pointer;
}
.job-offer:hover {
    background-color: #f7f8fe;
}

/* EXCEL - JOB OFFERS*/
.slide-fade-enter-active {
  transition: all .5s  cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-leave-active {
  transition: all .0000000000000000001s ease;
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(10px);
  opacity: 0;
}

/* BUTTONS */
.slide-fade-icons-enter-active {
  transition: all .5s  cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-icons-leave-active {
  transition: all .000000000000000000000000000000000003s ease;
}
.slide-fade-icons-enter{
  opacity: 0;
}
.slide-fade-icons-leave-to{
  opacity: 0;
  display: none;
}
</style>