<template>
    <participant-ficha-component :ui="'employee'"></participant-ficha-component>
</template>

<script>
import ParticipantFichaComponent from '@/components/Admin-Employee-Participant/ParticipantFichaComponent.vue'

export default {
  components: { ParticipantFichaComponent },
    name: "ParticipantFicha",
    data: () => ({})
}
</script>