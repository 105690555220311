import { render, staticRenderFns } from "./WatSponsorDetails.vue?vue&type=template&id=ea274ad4&"
import script from "./WatSponsorDetails.vue?vue&type=script&lang=js&"
export * from "./WatSponsorDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports