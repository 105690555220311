<template>
    <participant-documents-component :ui="'employee'"></participant-documents-component>
</template>

<script>
import ParticipantDocumentsComponent from '@/components/Admin-Employee/Participant/Documents/ParticipantDocumentsComponent.vue'

export default {
    name: "ParticipantDocuments",
    components: { ParticipantDocumentsComponent },
    data: () => ({})
}
</script>