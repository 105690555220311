<template>
    <b-row>
        <b-col>
            <b-card class="shadow">
                <employee-calendar />
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import EmployeeCalendar from '@/components/Calendars/EmployeeCalendar.vue'

export default {
    name: 'ScheduleDashboard',
    components: { EmployeeCalendar },
    data: () => ({}),
  }
</script>