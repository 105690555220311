<template>
    <!-- nvl 3 -->
    <b-row>
        <b-col cols="12" class="mb-5" >
            <div class="intranet-nav-content">
                <intranet-nav-content-link-exact
                    :link="{ name: 'Agenda: asesor de ventas', path: { name: 'employee-schedule-SA-interview' } }"
                    v-show="hasSalesAdvisorPermission"/>
                <intranet-nav-content-link
                    :link="{ name: 'Agenda: asesor cultural', path: { name: 'employee-schedule-CA-interview' } }"
                    v-show="hasCulturalAdvisorPermission"/>
            </div>
        </b-col>

        <b-col>
            <slide-x-left-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-x-left-transition>
        </b-col>
    </b-row>
</template>


<script>
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import { SlideXLeftTransition   } from 'vue2-transitions';

export default {
    name: 'ScheduleCreate',
    components: { 
        IntranetNavContentLink,
        IntranetNavContentLinkExact,
        SlideXLeftTransition 
    },

    computed: {
        hasSalesAdvisorPermission() {
            return this.$store.getters['user/hasEmployeePermission']('asesor-ventas')
        },
        hasCulturalAdvisorPermission() {
            return this.$store.getters['user/hasEmployeePermission']('asesor-cultural')
        },
    }
};
</script>

<style scoped>
.intranet-nav-content {
    display: flex;
    align-items: center;
    overflow-x:auto;
    white-space:nowrap;
}
</style>