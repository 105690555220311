<template>
    <b-row align-h="center">
        <b-col cols="12" md="6">
            <b-card class="shadow">
                <b-card-header class="border-0">
                    <b-row align-h="center">
                        <b-col cols="auto">
                            <h3 class="mb-0">
                                LOGIN
                            </h3>
                        </b-col>
                    </b-row>
                </b-card-header>


                <b-alert variant="danger" :show="errorResponseMessage ? true : false">
                    {{ errorResponseMessage }}
                </b-alert>

                <b-form @submit.prevent="iniciarSesion">
                    <b-form-row>
                        <b-col>
                            <b-form-group
                                label="Correo"
                            >
                                <pulse-loader
                                    :loading="loginLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-input 
                                    type="email"
                                    placeholder="Ingrese su correo."
                                    v-model="email"
                                    :state="showInputStatus('email')"
                                    v-show="!loginLoading"
                                />

                                <b-form-invalid-feedback
                                    v-for="(inputError, i) in showInputErrors('email')"
                                    :key="`${i}-login-error-email`"
                                >
                                    {{ inputError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col>
                            <b-form-group
                                label="Contraseña"
                            >
                                <pulse-loader
                                    :loading="loginLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-input 
                                    type="password"
                                    placeholder="Ingrese su contraseña."
                                    v-model="password"
                                    :state="showInputStatus('password')"
                                    v-show="!loginLoading"
                                />

                                <b-form-invalid-feedback
                                    v-for="(inputError, i) in showInputErrors('password')"
                                    :key="`${i}-login-error-password`"
                                >
                                    {{ inputError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-row class="justify-content-center">
                        <b-col cols="12" sm="8">
                            <b-button
                                class="w-100 my-2"
                                variant="primary"
                                type="submit"
                                :disabled="loginLoading"
                            >INICIAR</b-button>
                        </b-col>
                    </b-form-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { mapState } from 'vuex'
//import { PulseLoader } from '@saeris/vue-spinners'
import store from '@/store'

export default {
    name: 'login',
    //components: { PulseLoader },
    data: () => ({
        email: '',
        password: '',
    }),

    computed: {
        ...mapState('user', [
            'attributes',
            'loginLoading',
            'initialInputValues',
            'inputErrors',
            'errorResponseMessage'
        ]),
    },

    mounted() {
        console.log(`LOg: Bearer ${this.$store.state.user.attributes.token}` );
    },

    methods: {
        iniciarSesion () {
            this.$store.dispatch(
                'user/userLogin',
                { 'email':this.email, 'password':this.password },
                { root: true }
            )
        },
        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },
        showInputStatus (pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        }
    },

    watch: {
        attributes () {
            if (Object.keys(this.attributes).length !== 0 ) {
                if (store.getters['user/getActiveRoles'].length != 0) {
                    switch (store.getters['user/getActiveRoles'][0].value) {
                        case 'admin':
                            this.$router.push({ name : 'admin-dashboard' })
                            break;
                        case 'empleado':
                            this.$router.push({ name : 'employee-dashboard' })
                            break;
                        case 'participante':
                            this.$router.push({ name : 'participant-dashboard' })
                            break;
                        case 'postulante':
                            this.$router.push({ name : 'postulant-dashboard' })
                            break;
                        default:
                            console.log('LOGIN WATCH BREAK')
                            break;
                    }
                } else this.$router.push({ name : 'notActiveRoles-dashboard' })
              
            }
        }
    }

  }
</script>