<template>
    <english-interview-details-component></english-interview-details-component>
</template>

<script>
import EnglishInterviewDetailsComponent from '@/components/Admin-Employee/Postulant-Participant/EnglishInterviewDetailsComponent.vue'

export default {
    name: "InterviewDetails",
    components: { EnglishInterviewDetailsComponent },
    data: () => ({})
}
</script>

