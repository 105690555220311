<template>
    <b-row>
        <b-col>
            <b-card no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                list
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>N° post.</th>
                            <th>sponsor</th>
                            <th>oferta laboral</th>
                            <th>estado</th>
                            <th class="text-center">detalles</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>
                            <td>{{ row.attributes.subject }}</td>
                            <td>{{ row.attributes.sponsor_name }}</td>
                            <td>{{ row.job_offer_title_name }}</td>
                            <td>{{ row.attributes.status }}</td>

                            <td class="text-center">
                                <b-btn 
                                    :to="{
                                        name: 'participant-program-attempt-details',
                                        params: { 'IdPostulation': row.id }    //IdPostulation
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                   <b-icon icon="three-dots" scale="1.3"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
// programSeasonParticipant.postulation
import { getProgramSeasonParticipantPostulations } from '@/api/ProgramSeasonParticipant/Postulation';
export default {
    name: "AttemptsList",
    props: {
        routeName : {
            type: String
        }
    },
    data: () => ({
        tableData: [],
        loadingTableData: false,
    }),
    created() {
        this.getData()
    },
    methods: {
        getData () {
            this.loadingTableData = true
            let IdProgramSeasonParticipant = this.$route.params.IdProgramSeasonParticipant    // ok
            getProgramSeasonParticipantPostulations(IdProgramSeasonParticipant)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableData = this.tableData.map(el => {
                        let job_offer_title_name = ''
                        let job_title_order = el.attributes.programSeasonSponsor.job_title_order.split('|')
                        let jobFields = el.attributes.programSponsorJob.attributes.jobFields

                        for (const titleOrder of job_title_order) {
                            for (const field of jobFields) {
                                if (titleOrder == field.attributes.programJobField_id) {
                                    if (job_title_order.indexOf(titleOrder) == 0) job_offer_title_name = field.attributes.field_value
                                    else job_offer_title_name += ` - ${field.attributes.field_value}`
                                    break;
                                }
                            }
                        }
                        return { ...el, job_offer_title_name }
                    })
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },
        reloadData() {
            this.getData()
        },
    }
}
</script>