<template>
    <attempts-list-component
        routeNameAtemptDetails="employee-participants-attempt-details"
    ></attempts-list-component>
</template>

<script>
import AttemptsListComponent from '@/components/Admin-Employee/Participant/ParticipantAttempts/AttemptsListComponent.vue'

export default {
    name: 'AttemptsList',
    components: { AttemptsListComponent },
}
</script>