<template>
    <attempt-details-component :ui="'cultural_advisor'"></attempt-details-component>
</template>

<script>
import AttemptDetailsComponent from '@/components/Admin-Employee/Participant/ParticipantAttempts/Attempt/AttemptDetailsComponent.vue'

export default {
  name: 'AttemptDetails',
  components: { AttemptDetailsComponent },
}
</script>