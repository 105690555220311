<template>
    <participant-details-component :ui="'cultural_advisor'" :routeToList="'employee-participants-list'"></participant-details-component>
</template>

<script>
import ParticipantDetailsComponent from '@/components/Admin-Employee/Participant/Details/ParticipantDetailsComponent.vue'

export default {
    name: "ParticipantDetails",
    components: { ParticipantDetailsComponent },
    data: () => ({}),
}
</script>