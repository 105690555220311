import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function checkCapacitationSheduleMaterial (pFormData) {
    const URI = `${BASE_URL}/api/check/capacitationScheduleMaterial`
    return axios.post(URI, pFormData, { headers })
}
export {
    checkCapacitationSheduleMaterial
}