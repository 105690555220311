<template>
    <!-- nvl 2 -->
    <intranet-nav-content>
        <template slot="links">
            <div class="intranet-nav-content">
                <intranet-nav-content-link-exact :link="{ name: 'Programas', path: { name: 'participant-programs-list' } }"/>
            </div>
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
// import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';

export default {
    name: 'Programs',
    components: { 
        IntranetNavContent,
        // IntranetNavContentLink,
        IntranetNavContentLinkExact
    },
};
</script>

<style scoped>
.intranet-nav-content {
    display: flex;
    align-items: center;
    overflow-x:auto;
    white-space:nowrap;
}
</style>