<template>
    <div>
        <base-header class="header pb-8 pt-16 pt-lg-18"
            style="min-height: 370px; background-image: url(/img/theme/profile-cover.jpg);
            background-size: cover; background-position: center 10%;"
        >
            <!-- Mask -->
            <span class="mask bg-gradient-violeta opacity-7"></span>
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col-lg-7 col-md-10">
                        <h1 class="display-2 text-white mt-2">APK</h1>
                    </div>
                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--11">
            <bank-information></bank-information>
        </div>

    </div>
</template>

<script>
import BankInformation from '@/components/Apk/BankInformation.vue';

export default {
    name: 'Apk',
    components: {BankInformation }
};
</script>

