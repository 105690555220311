<template>
    <b-row align-h="center">
        <b-col cols="12">
            <divider-component></divider-component>
        </b-col>

        <b-col cols="auto" class="mb-4">
            <b-card no-body border-variant="transparent" bg-variant="transparent" class="glass">
                <div style="height:40px;width:250px" v-show="postulantLoading">
                    <pulse-loader
                        :color="'#fff'"
                        :margin="'8px'"
                        :size="10"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <b-card-body
                    v-show="!postulantLoading"
                    class="py-1 text-uppercase text-center text-white"
                    style="font-weight: bolder"
                    body-border-variant="transparent"
                    body-bg-variant="transparent"
                >
                    {{ orderOfPostulation }}
                </b-card-body>
            </b-card>
        </b-col>

        <b-col cols="12" class="mb-4">
            <div class="intranet-nav-content">
                <intranet-nav-content-link-exact :link="{ name: 'detalles', path: { name: 'participant-program-attempt-details' } }"/>
                <!-- <intranet-nav-content-link :link="{ name: 'Entrevistas Sponsor', path: { name: 'participant-program-attempt-sponsor-interviews' } }"/> -->
            </div>
        </b-col>

        <b-col>
            <slide-y-down-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-y-down-transition>
        </b-col>
    </b-row>

</template>

<script>
import { getPostulation } from '@/api/Postulation/Postulation';
// import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import { SlideYDownTransition } from 'vue2-transitions';
import DividerComponent from '@/components/UI/DividerComponent.vue';

export default {
    name: 'Attempt',
    components: { 
        // IntranetNavContentLink,
        IntranetNavContentLinkExact,
        SlideYDownTransition,
        DividerComponent,
    },
    data: () => ({
        postulation: {},
        postulantLoading: false
    }),
    created () {
        this.getData()
    },
    computed: {
        orderOfPostulation () {
            let orderOfPost = ''
            switch (this.postulation.subject) {
                case 'Primera opción':
                    orderOfPost = 'Primera postulación'
                    break;
                case 'Segunda opción':
                    orderOfPost = 'Segunda postulación'
                    break;
                case 'Tercera opción':
                    orderOfPost = 'Tercera postulación'
                    break;
                case 'Cuarta opción':
                    orderOfPost = 'Cuarta postulación'
                    break;
                default:
                    break;
            }
            return orderOfPost
        }
    },
    methods: {
        getData () {
            this.postulantLoading = true
            getPostulation(this.$route.params.IdPostulation)
                .then(({data}) => {
                    this.postulation = data.data.attributes
                })
                .finally(() => {
                    this.postulantLoading = false
                })
        }
    }
};
</script>

<style scoped>
.glass {
    background-color: rgba(255, 255, 255, .3) !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    color: white !important;
    border:1px solid rgba(255, 255, 255, 0.9) !important;
    overflow: hidden;
}
.intranet-nav-content {
    display: flex;
    align-items: center;
    overflow-x:auto;
    white-space:nowrap;
}
</style>