<template>
    <program-sponsor-details />
</template>

<script>
import ProgramSponsorDetails from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramSponsors/ProgramSponsorDetails/ProgramSponsorDetails.vue'

export default {
    name: "TraineeSponsorDetails",
    components: { ProgramSponsorDetails }
}
</script>