<template>
    <!-- nvl 4 -->
    <b-row>
        <b-col cols="12" class="mb-5" >
            <div class="intranet-nav-content">
                <intranet-nav-content-link-exact :link="{ name: 'Entrevista', path: { name: 'employee-schedule-SA-interview' } }"/>
                <!-- <intranet-nav-content-link :link="{ name: 'Agenda: asesor cultural', path: { name: 'employee-schedule-CA' } }"/> -->
            </div>
        </b-col>

        <b-col>
            <slide-x-right-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-x-right-transition>
        </b-col>
    </b-row>
</template>

<script>
// import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import { SlideXRightTransition } from 'vue2-transitions';


export default {
    name: 'ScheduleSA',
    components: { 
        // IntranetNavContentLink,
        IntranetNavContentLinkExact,
        SlideXRightTransition 
    },
}
</script>

<style scoped>
.intranet-nav-content {
    display: flex;
    align-items: center;
    overflow-x:auto;
    white-space:nowrap;
}
</style>