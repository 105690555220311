<template>
    <program-sponsor-programation />
</template>

<script>
import ProgramSponsorProgramation from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramSponsors/ProgramSponsorDetails/ProgramSponsorProgramation.vue'

export default {
    name: "WatSponsorProgramation",
    components: { ProgramSponsorProgramation }
}
</script>