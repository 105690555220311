import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function storeCapacitationSchedule (pFormData) {
    const URI = `${BASE_URL}/api/capacitationSchedules`
    return axios.post(URI, pFormData, { headers })
}

// delete
function destroyCapacitationSchedule (pIdCapacitationSchedule) {
    const URI = `${BASE_URL}/api/capacitationSchedules/${pIdCapacitationSchedule}`
    return axios.delete(URI, { headers })
}

export { 
    storeCapacitationSchedule,
    destroyCapacitationSchedule
 }