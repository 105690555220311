<template>
    <program-details></program-details>
</template>

<script>
import ProgramDetails from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramDetails.vue'

export default {
    name: "InternshipDetails",
    components: { ProgramDetails },
}
</script>