<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>
                
                <!-- table salesAdvisor.season.programSeasonPostulants -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th class="text-center">CONTRATO - RELLENAR</th>
                            <th class="text-center">DOC. GENERADO - ESTADO</th>
                            <th>Costo</th>
                            <th>Expiración</th>
                            <th>Descripción</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                {{ index + 1 }}
                            </td>

                            <td class="text-center">
                                <!-- pdf -->
                               <b-button variant="outline-primary" size="sm" :href="row.attributes.generated_file" target="_black" v-show="row.attributes.type == 'pdf'">
                                    <b-icon icon="file-earmark-text" class="m-0 p-0"></b-icon>
                                </b-button>

                                <!-- contract_html -->
                               <b-button variant="outline-primary" size="sm" @click="showPreviewFromContract(row.attributes.HtmlContract)" v-show="row.attributes.type == 'html'">
                                    <b-icon icon="pencil" scale="1.2" class="m-0 p-0"></b-icon>
                                </b-button>
                            </td>

                            <td>
                                <div class="mb-2 text-center">
                                    <!-- contract_pdf_generated -->
                                    <span v-show="row.attributes.type == 'html' && row.attributes.HtmlContract.verification_file">
                                        <b-button variant="outline-primary" size="sm" :href="row.attributes.HtmlContract.verification_file" target="_blank"
                                        ><b-icon icon="link" scale="1.2" class="m-0 p-0"></b-icon></b-button>
                                    </span>

                                    <!-- contract_pdf_generated -->
                                    <span v-show="row.attributes.type == 'html' && row.attributes.participantDocument_contrato" >
                                        <b-button variant="outline-primary" size="sm" :href="row.attributes.participantDocument_contrato" target="_blank"
                                        ><b-icon icon="link" scale="1.2" class="m-0 p-0"></b-icon></b-button>
                                    </span>

                                    <!-- status -->
                                    <span>
                                        <traffic-light :status="row.attributes.HtmlContract.verification_status" style="max-width: 110px"/>
                                    </span>
                                </div>

                                <div v-show="row.attributes.HtmlContract.verification_comments">
                                    {{ row.attributes.HtmlContract.verification_comments }}
                                </div>
                            </td>


                            <td>{{ row.attributes.total_cost }}</td>
                            <td>{{ row.attributes.expiration_date_formated }}</td>
                            <td>{{ row.attributes.description ? row.attributes.description : '- - - - - - - - - -'}}</td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!-- preview -->
        <contract-preview-component :preview="preview" :html="html_contract" :ui="'postulant'"></contract-preview-component>
    </b-row>
</template>

<script>
import { getProgramSeasonPostulantContracts } from '@/api/ProgramSeasonPostulant/Contracts';
import ContractPreviewComponent from '@/components/Tiptap/components/ContractPreviewComponent.vue'
import TrafficLight from '@/layout/Icons/TrafficLight.vue'

export default {
    name: 'ProgramContracts',
    components: {
        ContractPreviewComponent,
        TrafficLight,
    },
    data: () => ({
        // programSeasonPostulant.Contracts
        tableData: [],
        loadingTableData: false,
        //
        preview: 1,
        html_contract: {}
    }),

    created() {
        this.getPostulantContractsData()
    },

    methods: {
        getPostulantContractsData () {
            this.loadingTableData = true
            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant    // ok
            getProgramSeasonPostulantContracts(IdProgramSeasonPostulant)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        reloadData () {
            this.getPostulantContractsData()
        },

        showPreviewFromContract(pHtmlContract) {
            this.preview += 1
            this.html_contract = pHtmlContract
        }
    }
}
</script>