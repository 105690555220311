<template>
    <!-- nvl 3 -->
    <b-row>
        <b-col cols="12" class="mb-4">
            <b-card no-body border-variant="transparent" bg-variant="transparent" class="glass">
                <div style="height:40px;" v-show="programSeasonParticipantLoading">
                    <pulse-loader
                        :color="'#fff'"
                        :margin="'10px'"
                        :size="15"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <b-card-body
                    v-show="!programSeasonParticipantLoading"
                    class="py-2 text-uppercase text-center text-white"
                    style="font-weight: bolder"
                    body-border-variant="transparent"
                    body-bg-variant="transparent"
                >
                    {{ programSeasonParticipant.attributes.participant_full_name }} - {{ programSeasonParticipant.attributes.program }} - {{ programSeasonParticipant.attributes.season }}
                </b-card-body>
            </b-card>
        </b-col>

        <b-col cols="12" class="mb-4">
            <div class="intranet-nav-content">
                <intranet-nav-content-link-exact :link="{ name: 'Detalles', path: { name: 'participant-program-details' } }"/>
                <!--  -->
                <intranet-nav-content-link :link="{ name: 'Ficha', path: { name: 'participant-program-ficha' } }"/>
                <intranet-nav-content-link :link="{ name: 'Pagos', path: { name: 'participant-program-payments-vouchers' } }"/>
                <intranet-nav-content-link :link="{ name: 'Contratos', path: { name: 'participant-program-contracts' } }"/>
                <intranet-nav-content-link :link="{ name: 'Documentos', path: { name: 'participant-program-documents' } }"/>
                <!--  -->
                <intranet-nav-content-link :link="{ name: 'Recursos', path: { name: 'participant-program-resources' } }"/>
                <intranet-nav-content-link :link="{ name: 'Capacitaciones', path: { name: 'participant-program-capacitations-topics' } }"/>
                <intranet-nav-content-link :link="{ name: 'Charlas', path: { name: 'participant-program-talks-topics' } }"/>
                <intranet-nav-content-link :link="{ name: 'Visa', path: { name: 'participant-program-visa' } }"/>
                <intranet-nav-content-link :link="{ name: 'Formularios', path: { name: 'participant-forms' } }"/>
                <!--  -->
                <intranet-nav-content-link :link="{ name: 'Sponsors', path: { name: 'participant-program-sponsors-list' } }"/>
                <intranet-nav-content-link :link="{ name: 'Postulaciones', path: { name: 'participant-program-attempts-list' } }"/>
            </div>
        </b-col>

        <b-col>
            <slide-x-left-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-x-left-transition>
        </b-col>
    </b-row>

</template>

<script>
import { getProgramSeasonParticipant } from '@/api/ProgramSeasonParticipant/ProgramSeasonParticipant.js'
//
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink'
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact'
import { SlideXLeftTransition   } from 'vue2-transitions';
//import { PulseLoader } from '@saeris/vue-spinners'

export default {
    name: 'Program',
    components: { 
        IntranetNavContentLink,
        IntranetNavContentLinkExact,
        SlideXLeftTransition,
        // PulseLoader
    },
    data: () => ({
        programSeasonParticipant: {
            attributes: {}
        },
        programSeasonParticipantLoading: false
    }),
    created () {
        this.getData()
    },
    methods: {
        getData () {
            this.programSeasonParticipantLoading = true

            getProgramSeasonParticipant(this.$route.params.IdProgramSeasonParticipant)
                .then(({data}) => {
                    this.programSeasonParticipant = data.data
                })
                .catch(err => {
                    console.log('getProgramSeasonParticipant err', err);
                })
                .finally(() => {
                    this.programSeasonParticipantLoading = false
                    console.log('request getProgramSeasonParticipant end');
                })
        }
    }
};
</script>

<style scoped>
.glass {
    background-color: rgba(255, 255, 255, .3) !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    color: white !important;
    border:1px solid rgba(255, 255, 255, 0.9) !important;
    overflow: hidden;
}
.intranet-nav-content {
    display: flex;
    align-items: center;
    overflow-x:auto;
    white-space:nowrap;
}
</style>