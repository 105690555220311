<template>
    <b-row>
        <b-col cols="12" class="mb-4">
            <div class="intranet-nav-content apk-nav-tabs">
                <intranet-nav-content-link-exact :link="{ name: 'Postulantes', path: { name: 'employee-postulants-list-CA' } }"/>
                <!-- <intranet-nav-content-link :link="{ name: 'Test', path: { name: 'employee-postulants-list-test-CA' } }"/> -->
                <intranet-nav-content-link :link="{ name: 'Crear', path: { name: 'employee-postulants-create-CA' } }"/>
            </div>
        </b-col>

        <b-col>
            <router-view></router-view>
        </b-col>
    </b-row>
</template>

<script>
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';

export default {
    name: 'CulturalAdvisor',
    components: { 
        IntranetNavContentLink,
        IntranetNavContentLinkExact
    },
};
</script>

<style scoped>
.intranet-nav-content {
    display: flex;
    align-items: center;
    overflow-x:auto;
    white-space:nowrap;
}
</style>
