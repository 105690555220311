import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function storeTalkSchedule (pFormData) {
    const URI = `${BASE_URL}/api/talkSchedules`
    return axios.post(URI, pFormData, { headers })
}

function destroyTalkSchedule (pIdTalkSchedule) {
    const URI = `${BASE_URL}/api/talkSchedules/${pIdTalkSchedule}`
    return axios.delete(URI, { headers })
}

export { storeTalkSchedule, destroyTalkSchedule }