<template>
    <sponsor-job-offers-list-component
      :routeName="'employee-sponsor-jobs-offer-inputs'"
    ></sponsor-job-offers-list-component>
</template>

<script>
import SponsorJobOffersListComponent from '@/components/Managment/Sponsors/SponsorJobOffers/SponsorJobOffersListComponent.vue'

export default {
  components: { SponsorJobOffersListComponent },
    name: 'SponsorJobOffersList'
}
</script>