<template>
    <b-row>
        <b-col cols="12">
            <b-card class="shadow">
                <b-row>
                    <b-col cols="12" lg="4">
                        <b-row>
                            <!-- Participante -->
                            <b-col cols="12" class="mb-2">
                                <b-card border-variant="transparent">
                                    <b-card-header>
                                        <b-col class="text-center">
                                            <h3 class="mb-0 text-primary text-button">
                                                Participantes
                                            </h3>
                                        </b-col>
                                    </b-card-header>

                                    <b-row class="mt-5">
                                        <b-col cols="12" class="mb-4">
                                            <b-button
                                                block
                                                variant="outline-primary"
                                                size="sm"
                                                @click="modalChooseParticipantClick"
                                                :disabled="createTalkLoading"
                                            >
                                                Seleccionar Participante
                                            </b-button>
                                        </b-col>

                                        <b-col
                                            v-for="(participant, i) in selectedParticipants" :key="`${i}-participante`"
                                            cols="12"
                                            class="d-flex justify-content-between align-items-center"
                                        >
                                            <span class="text-uppercase">
                                                {{ participant.participant_full_name }}
                                            </span>

                                            <b-button
                                                variant="danger"
                                                size="sm"
                                                @click="selectedParticipants.splice(i, 1)"
                                                :disabled="createTalkLoading"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-x-fill" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
                                                </svg>
                                            </b-button>
                                        </b-col>

                                        <b-col cols="12">
                                            <span
                                                v-for="(inputError, i) in showModalInputErrors('programSeasonParticipant_id')"
                                                :key="`${i}-capac-program-season-participant_id`"
                                                class="text-danger text-caption"
                                            >
                                                {{ inputError == 'El campo participante es obligatorio.' ? 'Debes seleccionar un participante.' : inputError }}
                                            </span>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>

                            <!-- Materials -->
                            <b-col cols="12" class="mb-2">
                                <b-card border-variant="transparent">
                                    <b-card-header>
                                        <b-col class="text-center">
                                            <h3 class="mb-0 text-primary text-button">
                                                Materiales
                                            </h3>
                                        </b-col>
                                    </b-card-header>

                                    <b-row class="mt-5">
                                        <b-col cols="12" class="mb-4">
                                            <b-button
                                                block
                                                variant="outline-primary"
                                                size="sm"
                                                @click="modalAddMaterialClick"
                                                :disabled="createTalkLoading"
                                            >
                                                Agregar material
                                            </b-button>
                                        </b-col>

                                        <b-col
                                            v-for="(material, i) in materials" :key="`${i}-material`"
                                            cols="12"
                                            class="d-flex justify-content-between align-items-center"
                                        >
                                            <span class="text-uppercase">
                                                {{ material.name }}
                                            </span>

                                            <b-button
                                                variant="danger"
                                                size="sm"
                                                @click="materials.splice(i, 1)"
                                                :disabled="createTalkLoading"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z"/>
                                                </svg>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-col>

                    <!-- HORARIO -->
                    <b-col cols="12" lg="8">
                        <b-card border-variant="0" no-body>
                             <b-card-header>
                                <b-col class="text-center">
                                    <h3 class="mb-0 text-primary text-button">
                                        Horario
                                    </h3>
                                </b-col>
                            </b-card-header>

                            <b-form @submit.prevent="createTalkShedule" class="mt-8">
                                <b-form-row>
                                    <!-- subject -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group label="Nombre de la charla:" >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Ingrese el nombre de la charla."
                                                v-model="subject"
                                                :state="showModalInputStatus('subject')"
                                                v-show="!createTalkLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('subject')"
                                                :key="`${i}-createVoucher-subject`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- start_date -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group
                                            label="Fecha :"
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="start_date"
                                                :state="showModalInputStatus('start_date')"
                                                v-if="!createTalkLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('start_date')"
                                                :key="`${i}-interview-start_date`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- start_time -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group
                                            label="Hora de inicio :"
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-timepicker
                                                locale="en"

                                                now-button
                                                label-now-button="now"
                                                now-button-variant="outline-naranja"

                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="start_time"
                                                :state="showModalInputStatus('start_time')"
                                                v-if="!createTalkLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('start_time')"
                                                :key="`${i}-interview-start_time`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- end_time -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group
                                            label="Hora de finalización :"
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-timepicker
                                                locale="en"

                                                now-button
                                                label-now-button="now"
                                                now-button-variant="outline-naranja"

                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="end_time"
                                                :state="showModalInputStatus('end_time')"
                                                v-if="!createTalkLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('end_time')"
                                                :key="`${i}-interview-end_time`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- link -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group label="Link :" >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="url"
                                                placeholder="Ingrese el link de la reunión."
                                                v-model="link"
                                                :state="showModalInputStatus('link')"
                                                v-show="!createTalkLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('link')"
                                                :key="`${i}-createVoucher-link`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- description -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="schedule_description"
                                                :state="showModalInputStatus('schedule_description')"
                                                v-show="!createTalkLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('schedule_description')"
                                                :key="`${i}-interview-schedule_description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- request error -->
                                <b-row v-if="requestError != ''">
                                    <b-col>
                                        <b-alert show variant="magenta">
                                            {{ requestError }}
                                        </b-alert>
                                    </b-col>
                                </b-row>

                                <!-- button create -->
                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button
                                            variant="primary"
                                            type="submit"
                                            size="lg"
                                            :disabled="createTalkLoading"
                                            >
                                            AGENDAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card>
                    </b-col>
                    
                </b-row>
            </b-card>
        </b-col>

        <!--
            modal choose participant
        -->
        <b-modal
            id="modal-choose-participant"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">SELECCIONAR PARTICIPANTE</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalChooseParticipantClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- season -->
                        <b-col cols="12">
                            <b-form-group label="Temporada :">
                                <pulse-loader
                                    :loading="getSeasonOptionsLoading || loadingTableData"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-select
                                    class="text-center"
                                    v-model="seasonSelected"
                                    :options="seasonOptions"
                                    v-show="!getSeasonOptionsLoading && !loadingTableData"
                                    @change="searchParticipant(1)"
                                />
                            </b-form-group>
                        </b-col>

                        <!-- search participant -->
                        <b-col cols="12">
                            <b-form @submit.prevent="searchParticipant">
                                <b-form-row>
                                    <b-col cols="10">
                                        <b-form-group>
                                            <pulse-loader
                                                :loading="loadingTableData"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Búsqueda por nombres o dni."
                                                v-model="inputToSearch"
                                                :state="showModalInputStatus('input_to_search')"
                                                v-show="!loadingTableData"
                                            />

                                            <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('input_to_search')" :key="`${i}-serchUsers`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="2">
                                        <b-form-group>
                                            <b-button
                                                class="w-100 py-auto px-0"
                                                variant="primary"
                                                type="submit"
                                            ><b-icon icon="search" class="m-0 p-0"/></b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                            <!-- laodingTableData -->
                            <div style="height:150px;" v-show="loadingTableData">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="20"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>

                            <!-- card participants table -->
                            <b-card no-body>
                                <div class="table-responsive mb-0" v-show="!loadingTableData && !searchFailed">
                                    <base-table class="table align-items-center table-flush"
                                        thead-classes="thead-light"
                                        tbody-classes="list"
                                        :data="tableData"
                                    >
                                        <template slot="columns">
                                            <th></th>
                                            <th>Nombres</th>
                                            <th>Programa</th>
                                            <th>temporada</th>
                                        </template>

                                        <template slot-scope="{row}">
                                            <td class="text-center">
                                                <b-btn 
                                                    variant="outline-primary"
                                                    size="sm"
                                                    @click="choseParticipant(row.attributes)"
                                                ><b-icon icon="person-plus-fill" class="m-0 p-0"/>
                                                </b-btn>
                                            </td>
                                            <td class="text-uppercase">
                                                <span>{{ row.attributes.participant_full_name }}</span>
                                            </td>
                                            <td>{{ row.attributes.program}}</td>
                                            <td>{{ row.attributes.season}}</td>
                                        </template>
                                    </base-table>
                                </div>
                            </b-card>

                            <!-- pagination -->
                            <div class="card-footer border-0" v-show="!loadingTableData && !searchFailed">
                                <base-pagination
                                    class="mb-0"
                                    align="center"
                                    :pageCount="tableMeta.last_page" 
                                    :perPage="tableMeta.per_page"
                                    :value="tableMeta.current_page"
                                    @input="paginationCLiked"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

         <!--
            modal add material
        -->
        <b-modal
            id="modal-add-material"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">AGREGAR MATERIAL</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalAddMaterialClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- add material -->
                        <b-col cols="12">
                            <b-form @submit.prevent="addMaterial">
                                <b-form-row>
                                    <!-- name -->
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Nombre :"
                                        >
                                            <pulse-loader
                                                :loading="talkScheduleMaterialCheckLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Ingrese el nombre del material."
                                                v-model="addMaterialItem.name"
                                                :state="showModalInputStatus('name')"
                                                v-show="!talkScheduleMaterialCheckLoading"
                                            />

                                            <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('name')" :key="`${i}-name`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- file -->
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Documento (max 512Kbs):"
                                        >
                                            <pulse-loader
                                                :loading="talkScheduleMaterialCheckLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-file
                                                placeholder="Ingrese el nombre del material."
                                                v-model="addMaterialItem.file"
                                                style="overflow:hidden;"
                                                :state="showModalInputStatus('file')"
                                                v-show="!talkScheduleMaterialCheckLoading"
                                            />

                                            <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('file')" :key="`${i}-file`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- shared_link -->
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Link compartido : "
                                        >
                                            <pulse-loader
                                                :loading="talkScheduleMaterialCheckLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea
                                                type="text"
                                                placeholder="Ingrese el link compartido del material.(Si el recurso es pesado)"
                                                v-model="addMaterialItem.shared_link"
                                                :state="showModalInputStatus('shared_link')"
                                                v-show="!talkScheduleMaterialCheckLoading"
                                            />

                                            <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('shared_link')" :key="`${i}-shared_link`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- description -->
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción :"
                                        >
                                            <pulse-loader
                                                :loading="talkScheduleMaterialCheckLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea
                                                type="text"
                                                placeholder="Ingrese la descripción de material.(opcional)"
                                                v-model="addMaterialItem.description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!talkScheduleMaterialCheckLoading"
                                            />

                                            <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('description')" :key="`${i}-description`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <b-col>
                                        <b-form-group>
                                            <b-button
                                                class="w-100 py-auto px-0"
                                                variant="primary"
                                                type="submit"
                                            >Agregar</b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
import { getAllSeasons } from '@/api/Season/Season'
/* season.programSeasonParticipant-search */
import { seasonProgramSeasonParticipantSearch } from '@/api/Season/SeasonProgramSeasonParticipantSearch'
import { checkTalkSheduleMaterial } from '@/api/TalkScheduleMaterial/TalkScheduleMaterial'
import { storeTalkSchedule } from '@/api/TalkSchedule/TalkSchedule'
import swal from 'sweetalert'

export default {
    name: "CreateCapacitations",
    data: () => ({
        // seasons
        seasonOptions: [],
        seasonSelected: 2,
        getSeasonOptionsLoading: false,
        // participants
        inputToSearch: '',
        order_type: 'created',
        //
        tableData: [],
        tableMeta: {},
        searchFailed: true,
        loadingTableData: false,
        // modal choose participant
        selectedParticipants: [],
        participantstableData: [],

       // modal add material 
       materials: [],
       addMaterialItem: {
           name: '',
           file: null,
           shared_link: '',
           description: '',
       },
       talkScheduleMaterialCheckLoading: false,

       // talkSchedule
        subject: '',
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        link: '',
        color: '#5E8B7E',
        schedule_description: 'Charla',
        schedule_status: 'pendiente',
        createTalkLoading: false,

        initialModalInputValues : true,
        modalInputErrors: {},

        requestError: ''
    }),

    computed: {
        culturalAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-cultural')
        },
    },

    methods: {
        // get all seasons
        getSeasonsData() {
            this.getSeasonOptionsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                })
                .catch(err => {
                    console.log('getAllSeason error', err.response);
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        // Modal CHOOSE PARTICIPANT
        modalChooseParticipantClick () {
            this.getSeasonsData()
            this.clearInputsAndStates()
            this.searchParticipant()
            this.$bvModal.show('modal-choose-participant')
        },
        modalChooseParticipantClose () {
            this.$bvModal.hide('modal-choose-participant')
            this.clearInputsAndStates()
        },

        searchParticipant (pPage = 1) {
            this.initialModalInputValues = false
            this.loadingTableData = true
            this.modalInputErrors = {}

            let pIdSeason = this.seasonSelected

            let SearchFormData = new FormData()
            SearchFormData.append('input_to_search', this.inputToSearch)
            SearchFormData.append('order_type', this.order_type)
            SearchFormData.append('paginated_by', 7)
            SearchFormData.append('lite_resource', "si")

            seasonProgramSeasonParticipantSearch(pIdSeason, SearchFormData, pPage)
                .then(({data}) => {
                    this.tableData = data.data.filter(el => {
                        let add = 'si'

                        this.selectedParticipants.forEach(sel_part => {
                            if(sel_part.id == el.attributes.id) add = 'no'
                        });

                        return add == 'si'
                    })
                    this.tableMeta = data.meta
                    this.searchFailed = false
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.modalInputErrors = err.response.data.errors
                        this.searchFailed = true
                    }
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        choseParticipant (pParticipant) {
            this.selectedParticipants.push({ ...pParticipant })
            this.modalChooseParticipantClose()
        },

        paginationCLiked (e) {
            this.searchParticipant(e)
        },

        // Modal ADD MATERIAL
        modalAddMaterialClick () {
            this.clearInputsAndStates()
            this.$bvModal.show('modal-add-material')
        },
        modalAddMaterialClose () {
            this.$bvModal.hide('modal-add-material')
            this.clearInputsAndStates()
        },

        addMaterial() {
            this.talkScheduleMaterialCheckLoading = true
            this.initialModalInputValues = false
            this.requestError = ''
            this.modalInputErrors = {}

            let CapacitationScheduleMaterialForm = new FormData()
            CapacitationScheduleMaterialForm.append('name', this.addMaterialItem.name)
            CapacitationScheduleMaterialForm.append('file', this.addMaterialItem.file == null ? '' : this.addMaterialItem.file)
            CapacitationScheduleMaterialForm.append('shared_link', this.addMaterialItem.shared_link)
            CapacitationScheduleMaterialForm.append('description', this.addMaterialItem.description)

            checkTalkSheduleMaterial(CapacitationScheduleMaterialForm)
                .then(() => {
                    this.materials.push({
                        name: this.addMaterialItem.name,
                        file: this.addMaterialItem.file,
                        shared_link: this.addMaterialItem.shared_link,
                        description: this.addMaterialItem.description
                    })
                    this.modalAddMaterialClose()
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.talkScheduleMaterialCheckLoading = false
                    console.log('request create english interview end');
                })

        },

        // CREATE TALK-SCHEDULE
        createTalkShedule () {
            this.createTalkLoading = true
            this.modalInputErrors = {}
            this.initialModalInputValues = false

            let IdCulturalAdvisor = this.culturalAdvisorPermissionData.attributes.typeAdvisor_id   // ok

            let TalkSheduleForm = new FormData()
            TalkSheduleForm.append('employeeCalendar_id', this.$store.state.user.attributes.employeeCalendar_id)
            // TalkSheduleForm.append('salesAdvisor_id', IdSalesAdvisor)          // cuando la agenda es de SA
            TalkSheduleForm.append('culturalAdvisor_id', IdCulturalAdvisor)

            TalkSheduleForm.append('start_date', this.start_date)
            TalkSheduleForm.append('start_time', this.start_time)
            TalkSheduleForm.append('end_date', this.start_date)                   // this.start_date; la entrevista es el mismo dia
            TalkSheduleForm.append('end_time', this.end_time)
            
            TalkSheduleForm.append('subject', this.subject)
            TalkSheduleForm.append('link', this.link)
            TalkSheduleForm.append('color', this.color)                               // '#5E8B7'
            TalkSheduleForm.append('schedule_description', this.schedule_description) // 'Charla'
            TalkSheduleForm.append('schedule_status', this.schedule_status)           // 'pendiente


            TalkSheduleForm.append('numberOfParticipants', this.selectedParticipants.length)
            this.selectedParticipants.forEach((el_particiapant, i) => {
                TalkSheduleForm.append(`programSeasonParticipant_id-${i}`, el_particiapant.id)
                
            });

            TalkSheduleForm.append('numberOfMaterials', this.materials.length)
            this.materials.forEach((el_material, i) => {
                TalkSheduleForm.append(`name-${i}`, el_material.name)
                TalkSheduleForm.append(`file-${i}`, el_material.file)
                TalkSheduleForm.append(`shared_link-${i}`, el_material.shared_link)
                TalkSheduleForm.append(`description-${i}`, el_material.description)
                
            });

            storeTalkSchedule(TalkSheduleForm)
                .then(() => {
                    swal('Creación correcta!', 'Agendación de charla creada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: 'employee-schedule-dashboard'})
                                    break;
                                default:
                                    console.log('create talk res swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    } else {
                        this.requestError = err.response.status + ' : ' + err.response.data.message
                    }
                })
                .finally(() => {
                    this.createTalkLoading = false
                })
        },

        // globar input methods
        showModalInputErrors (pInput) {
            if (Object.keys(this.modalInputErrors).includes(pInput)) return this.modalInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalInputValues = true
            this.modalInputErrors = {}
            //
            this.inputToSearch = ''
            this.tableData = []
            this.tableMeta = {}
            //
            this.addMaterialItem = {
                name: '',
                file: null,
                shared_link: '',
                description: '',
            }
        }
    }
}
</script>