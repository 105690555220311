import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

// programSeasonSponsor.programSponsorJobs
function getProgramSeasonSponsorProgramation (pIdProgramSeasonSponsor) {
    const URI = `${BASE_URL}/api/programSeasonSponsor/${pIdProgramSeasonSponsor}/programation`
    return axios.get(URI, { headers })
}

function updateProgramation (pIdProgramSeasonSponsor, form) {
    const URI = `${BASE_URL}/api/programSeasonSponsor/${pIdProgramSeasonSponsor}/programation`
    return axios.post(URI, form, { headers })
}

export {
    getProgramSeasonSponsorProgramation,
    updateProgramation
}