<template>
    <b-row>
        <b-col cols="12" class="mb-5" >
            <div class="intranet-nav-content">
                <intranet-nav-content-link-exact :link="{ name: 'Postulantes', path: { name: 'employee-postulants-list-SA' } }"/>
                <intranet-nav-content-link :link="{ name: 'Crear', path: { name: 'employee-postulants-create-SA' } }"/>
            </div>
        </b-col>

        <b-col>
            <slide-x-left-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-x-left-transition>
        </b-col>
    </b-row>
</template>

<script>
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import { SlideXLeftTransition   } from 'vue2-transitions';

export default {
    name: 'SalesAdvisor',
    components: { 
        IntranetNavContentLink,
        IntranetNavContentLinkExact,
        SlideXLeftTransition 
    },
};
</script>

<style scoped>
.intranet-nav-content {
    display: flex;
    align-items: center;
    overflow-x:auto;
    white-space:nowrap;
}
</style>