import { post } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

// put
function capacitationScheduleUpdateSchedule (pFormData, pIdCapacitationSchedule) {
    const URI = `${BASE_URL}/api/capacitationSchedules/${pIdCapacitationSchedule}/updateSchedule`
    return post(URI, pFormData, { headers })
}

export { capacitationScheduleUpdateSchedule }