<template>
    <!-- Calendar by Vuetify - EmployeeCalendar -->
    <div>
        <v-row class="fill-height">
            <v-col>
                <v-sheet height="64">
                    <!-- <v-toolbar flat> -->
                        <div flat style="overflow:hidden" class="d-flex align-items-center">
                        <!-- btn today -->
                        <v-btn
                            outlined
                            class="mr-2"
                            color="grey darken-2"
                            @click="setToday"
                        >hoy
                        </v-btn>

                        <!-- reload -->
                        <b-button variant="outline-primary" size="sm" @click="reloadData"  class="mr-0 py-2">
                            <b-icon icon="arrow-repeat"></b-icon>
                        </b-button>

                        <!-- btn prev month -->
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                        >
                            <v-icon small>
                            mdi-chevron-left
                            </v-icon>
                        </v-btn>

                        <!-- month - year -->
                        <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                        </v-toolbar-title>

                        <!-- btn next month -->
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                        >
                            <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-menu bottom right >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    outlined
                                    color="grey darken-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <span>{{ typeToLabel[type] }}</span>
                                    <v-icon right>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item @click="type = 'day'">
                                    <v-list-item-title>Día</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'week'">
                                    <v-list-item-title>Semana</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'month'">
                                    <v-list-item-title>Mes</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = '4day'">
                                    <v-list-item-title>4 días</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    <!-- </v-toolbar> -->
                    </div>
                </v-sheet>

                <v-sheet height="600">
                    <v-calendar
                        locale="es"
                        :short-weekdays="false"
                        ref="calendar"
                        v-model="focus"
                        color="primary"
                        :events="events"
                        :event-color="getEventColor"
                        event-ripple
                        :type="type"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @change="updateRange"
                        >
                    </v-calendar>

                    <!-- evento seleccionado -->
                    <v-menu
                        v-model="selectedOpen"
                        :close-on-content-click="false"
                        :activator="selectedElement"
                        offset-x
                    >
                        <v-card
                            color="grey lighten-4"
                            class="card-event-display"
                            flat
                            :loading="selectedEvent.loading"
                            loader-height="8"
                        >
                            <v-toolbar :color="selectedEvent.color" dark>
                                <v-btn
                                    x-small elevation="5" icon fab class="mx-1"
                                    v-show="selectedEvent.schedule_status != 'culminado' ? true : false"
                                    @click.prevent="openModalShedule"
                                ><v-icon dark>mdi-pencil</v-icon></v-btn>

                                <v-btn
                                    x-small elevation="5" icon fab class="ml-1 mr-2"
                                    v-show="selectedEvent.schedule_status != 'culminado' ? true : false"
                                    @click.prevent="deleteSchedule"
                                ><v-icon>mdi-delete</v-icon></v-btn>

                                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>

                                <v-spacer></v-spacer>

                                <v-btn icon @click="selectedOpen = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>

                            <!-- EnglishInterviewSchedule -->
                            <div v-if="selectedEvent.card_type == 'EnglishInterviewSchedule'">  
                                <v-card-text>
                                    <b-row>
                                        <b-col class="text-caption text-uppercase text-center
                                            card-english-interview-schedule-agenda mx-2 py-1"
                                            :class="selectedEvent.schedule_status == 'pendiente' ? 'text-magenta' :( selectedEvent.schedule_status == 'disponible' ? 'text-schedule-english-2' : 'text-schedule-english-1')"
                                        >
                                            <span>Agenda </span>
                                            <span>{{ selectedEvent.salesAdvisor_id ? 'Asesor ventas' : 'Asesor cultural' }}</span>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption">
                                        <b-col cols="4" class="text-right">Estado</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                            <span   :class="selectedEvent.schedule_status == 'pendiente' ? 'text-magenta' :( selectedEvent.schedule_status == 'disponible' ? 'text-schedule-english-2' : 'text-schedule-english-1') ">
                                                {{ selectedEvent.schedule_status }}
                                            </span>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption"
                                        v-if="selectedEvent.details"
                                    >
                                        <b-col cols="4" class="text-right">Detalles</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                            <span>{{ selectedEvent.details }} </span>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption"
                                        v-if="selectedEvent.postulant_full_name"
                                    >
                                        <b-col cols="4" class="text-right">Postulante</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6" class="card-section-scrollable">
                                            <div class="text-capitalize" style="width:max-content">
                                                {{ selectedEvent.postulant_full_name }} - {{ selectedEvent.postulant_program_name}} - {{ selectedEvent.postulant_season_name }}
                                            </div>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption" align-v="center"
                                        v-show="selectedEvent.schedule_status == 'culminado' ? false : true"
                                    >
                                        <b-col cols="4" class="text-right">Reunión (link)</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                             <b-button
                                                :href="selectedEvent.link" target="_blanck"
                                                :variant="selectedEvent.schedule_status == 'pendiente' ? 'magenta' : 'schedule-english-2'"
                                                size="sm"
                                            ><b-icon icon="link" variant="white"></b-icon></b-button>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption" align-v="center"
                                        v-show="selectedEvent.schedule_status == 'culminado' ? false : true"
                                    >
                                        <b-col cols="4" class="text-right">Hora</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                            <span>{{ selectedEvent.start_time }} - {{ selectedEvent.end_time }}</span>
                                        </b-col>
                                    </b-row>
                                </v-card-text>

                                <v-card-actions style="display:flex;justify-content:center">
                                    <!-- detalles SA -->
                                    <v-btn
                                        text
                                        color="secondary"
                                        v-if="selectedEvent.programSeasonPostulant_id != null && selectedEvent.salesAdvisor_id != null"
                                        :to="{ 
                                            name: 'employee-postulants-interview-details-SA',
                                            params: { 
                                                'IdProgramSeasonPostulant': selectedEvent.programSeasonPostulant_id,
                                                'IdEnglishInterviewSchedule': selectedEvent.englishInterviewSchedule_id
                                            }
                                        }"
                                    >
                                        <!-- Detalles SA -->
                                        Detalles
                                    </v-btn>

                                    <!-- detalles CA -->
                                    <v-btn
                                        text
                                        color="secondary"
                                        v-if="selectedEvent.programSeasonPostulant_id != null && selectedEvent.culturalAdvisor_id != null"
                                        :to="{ 
                                            name: 'employee-postulants-interview-details-CA',
                                            params: { 
                                                'IdProgramSeasonPostulant': selectedEvent.programSeasonPostulant_id,
                                                'IdEnglishInterviewSchedule': selectedEvent.englishInterviewSchedule_id
                                            }
                                        }"
                                    >
                                        <!-- Detalles CA -->
                                        Detalles
                                    </v-btn>
                                </v-card-actions>
                            </div>

                            <!-- CapacitationSchedule -->
                            <div
                                v-if="selectedEvent.card_type == 'CapacitationSchedule'"
                                class="m-0 p-0"
                            >
                                <v-card-text>
                                    <b-row>
                                        <b-col class="text-caption text-uppercase text-center text-schedule-capacitation-3
                                            card-agenda mx-2 py-1"
                                        >
                                            <span>Agenda </span>
                                            <span>Asesor cultural</span>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption"
                                        v-if="selectedEvent.details"
                                    >
                                        <b-col cols="4" class="text-right">Detalles</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                            <span>{{ selectedEvent.details }} </span>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption">
                                        <b-col cols="4" class="text-right">Participantes</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6" class="card-section-scrollable">
                                            <div style="width:max-content; max-height: 130px">
                                                <div v-for="(participant, i) in selectedEvent.capacitationScheduleParticipants" :key="`p-${i}`">
                                                    {{ i + 1 }} <span class="text-schedule-capacitation-2">{{ participant.attributes.participant_full_name }}</span> - 
                                                    {{ participant.attributes.participant_program_name }} -
                                                    {{ participant.attributes.participant_season_name }}
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption" align-v="center"
                                        v-show="selectedEvent.schedule_status == 'culminado' ? false : true"
                                    >
                                        <b-col cols="4" class="text-right">Reunión (link)</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                             <b-button
                                                :href="selectedEvent.link" target="_blanck"
                                                :variant="selectedEvent.schedule_status == 'pendiente' ? 'schedule-capacitation-2' : 'schedule-capacitation-2'"
                                                size="sm"
                                            ><b-icon icon="link" variant="white"></b-icon></b-button>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption" align-v="center"
                                        v-show="selectedEvent.schedule_status == 'culminado' ? false : true"
                                    >
                                        <b-col cols="4" class="text-right">Hora</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                            <span>{{ selectedEvent.start_time }} - {{ selectedEvent.end_time }}</span>
                                        </b-col>
                                    </b-row>
                                </v-card-text>

                                <v-card-actions style="display:flex;justify-content:center">
                                    <!-- <v-btn
                                        text
                                        color="secondary"
                                        v-if="selectedEvent.programSeasonPostulant_id != null && selectedEvent.culturalAdvisor_id != null"
                                        :to="{ 
                                            name: 'employee-postulants-interview-details-CA',
                                            params: { 
                                                'IdProgramSeasonPostulant': selectedEvent.programSeasonPostulant_id,
                                                'IdEnglishInterviewSchedule': selectedEvent.englishInterviewSchedule_id
                                            }
                                        }"
                                    >
                                        Detalles
                                    </v-btn> -->
                                </v-card-actions>
                            </div>

                            <!-- TalkSchedule -->
                            <div
                                v-if="selectedEvent.card_type == 'TalkSchedule'"
                                class="m-0 p-0"
                            >
                                  <v-card-text>
                                    <b-row>
                                        <b-col class="text-caption text-uppercase text-center text-schedule-talk-3
                                            card-agenda mx-2 py-1"
                                        >
                                            <span>Agenda </span>
                                            <span>Asesor cultural</span>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption"
                                        v-if="selectedEvent.details"
                                    >
                                        <b-col cols="4" class="text-right">Detalles</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                            <span>{{ selectedEvent.details }} </span>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption">
                                        <b-col cols="4" class="text-right">Participantes</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6" class="card-section-scrollable">
                                            <div class="text-capitalize" style="width:max-content; max-height: 130px">
                                                <div v-for="(participant, i) in selectedEvent.talkScheduleParticipants" :key="`p-${i}`">
                                                    {{ i + 1 }} <span class="text-schedule-talk-2">{{ participant.attributes.participant_full_name }}</span> - 
                                                    {{ participant.attributes.participant_full_name }} -
                                                    {{ participant.attributes.participant_program_name }} -
                                                    {{ participant.attributes.participant_season_name }}
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption" align-v="center"
                                        v-show="selectedEvent.schedule_status == 'culminado' ? false : true"
                                    >
                                        <b-col cols="4" class="text-right">Reunión (link)</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                             <b-button
                                                :href="selectedEvent.link" target="_blanck"
                                                :variant="selectedEvent.schedule_status == 'pendiente' ? 'schedule-talk-2' : 'schedule-talk-2'"
                                                size="sm"
                                            ><b-icon icon="link" variant="white"></b-icon></b-button>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption" align-v="center"
                                        v-show="selectedEvent.schedule_status == 'culminado' ? false : true"
                                    >
                                        <b-col cols="4" class="text-right">Hora</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                            <span>{{ selectedEvent.start_time }} - {{ selectedEvent.end_time }}</span>
                                        </b-col>
                                    </b-row>
                                </v-card-text>

                                <v-card-actions style="display:flex;justify-content:center">
                                    <!-- <v-btn
                                        text
                                        color="secondary"
                                        v-if="selectedEvent.programSeasonPostulant_id != null && selectedEvent.culturalAdvisor_id != null"
                                        :to="{ 
                                            name: 'employee-postulants-interview-details-CA',
                                            params: { 
                                                'IdProgramSeasonPostulant': selectedEvent.programSeasonPostulant_id,
                                                'IdEnglishInterviewSchedule': selectedEvent.englishInterviewSchedule_id
                                            }
                                        }"
                                    >
                                        Detalles
                                    </v-btn> -->
                                </v-card-actions>
                            </div>

                        </v-card>
                    </v-menu>
                </v-sheet>
            </v-col>
            
        </v-row>
        <!-- 
            MODAL: UPDATE - SCHEDULE
        -->
        <b-modal
                id="modal-update-schedule"
                centered
                no-close-on-backdrop
                no-close-on-esc
                hide-footer
                size="md"
                header-class="pb-0"
                body-class="pt-0"
            >
                <!-- modal header -->
                <template #modal-header>
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="10">
                                <h3 class="text-primary mb-0 text-uppercase">
                                    Editar {{ selectedEvent.name }}
                                </h3></b-col>
                            <b-col cols="2" class="text-right">
                                <b-button size="sm" variant="magenta" @click="closeModalShedule"
                                >
                                    <b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal main content -->
                <template #default>
                    <b-container>
                        <b-form @submit.prevent="updateSchedule">
                            <b-form-row  class="my-2">
                                <!-- start_date -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Fecha :"
                                    >
                                        <pulse-loader
                                            :loading="updateScheduleLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-datepicker
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            selected-variant="primary"
                                            today-variant="naranja"
                                            locale="es"

                                            today-button
                                            label-today-button="today"
                                            today-button-variant="outline-naranja"
                                            
                                            reset-button
                                            label-reset-button="reset"
                                            reset-button-variant="outline-magenta"

                                            close-button
                                            label-close-button="ok"
                                            close-button-variant="primary"

                                            v-model="selectedEvent.start_date"
                                            :state="showModalScheduleInputStatus('start_date')"
                                            v-if="!updateScheduleLoading"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalScheduleInputErrors('start_date')"
                                            :key="`${i}-interview-start_date`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- start_time -->
                                <b-col cols="12" lg="6">
                                    <b-form-group
                                        label="Hora de inicio :"
                                    >
                                        <pulse-loader
                                            :loading="updateScheduleLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-timepicker
                                            locale="en"

                                            now-button
                                            label-now-button="now"
                                            now-button-variant="outline-naranja"

                                            reset-button
                                            label-reset-button="reset"
                                            reset-button-variant="outline-magenta"

                                            close-button
                                            label-close-button="ok"
                                            close-button-variant="primary"

                                            v-model="selectedEvent.start_time"
                                            :state="showModalScheduleInputStatus('start_time')"
                                            v-if="!updateScheduleLoading"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalScheduleInputErrors('start_time')"
                                            :key="`${i}-interview-start_time`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- end_time -->
                                <b-col cols="12" lg="6">
                                    <b-form-group
                                        label="Hora de finalización :"
                                    >
                                        <pulse-loader
                                            :loading="updateScheduleLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-timepicker
                                            locale="en"

                                            now-button
                                            label-now-button="now"
                                            now-button-variant="outline-naranja"

                                            reset-button
                                            label-reset-button="reset"
                                            reset-button-variant="outline-magenta"

                                            close-button
                                            label-close-button="ok"
                                            close-button-variant="primary"

                                            v-model="selectedEvent.end_time"
                                            :state="showModalScheduleInputStatus('end_time')"
                                            v-if="!updateScheduleLoading"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalScheduleInputErrors('end_time')"
                                            :key="`${i}-interview-end_time`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- link -->
                                <b-col cols="12">
                                    <b-form-group label="Link :" >
                                        <pulse-loader
                                            :loading="updateScheduleLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="url"
                                            placeholder="Ingrese el link de la reunión."
                                            v-model="selectedEvent.link"
                                            :state="showModalScheduleInputStatus('link')"
                                            v-show="!updateScheduleLoading"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalScheduleInputErrors('link')"
                                            :key="`${i}-createVoucher-link`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- description -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Descripción:" 
                                    >
                                        <pulse-loader
                                            :loading="updateScheduleLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-textarea 
                                            placeholder="Ingrese una descripción (opcional)"
                                            v-model="selectedEvent.schedule_description"
                                            :state="showModalScheduleInputStatus('schedule_description')"
                                            v-show="!updateScheduleLoading"
                                            rows="3"
                                            max-rows="6"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalScheduleInputErrors('schedule_description')"
                                            :key="`${i}-interview-schedule_description`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- btn actualizar -->
                            <b-form-row class="justify-content-center">
                                <b-col cols="12" sm="auto" class="py-0">
                                    <b-button
                                        class="w-100 my-2"
                                        variant="primary"
                                        type="submit"
                                        size="md"
                                        :disabled="updateScheduleLoading"
                                    >ACTUALIZAR</b-button>
                                </b-col>
                            </b-form-row>
                        </b-form>
                    </b-container>
                </template>
        </b-modal>
    </div>
</template>

<script>
import { getEmployeeEmployeeCalendar } from '@/api/Employee/EmployeeEmployeeCalendar'
// update schedules
import { englishInterviewUpdateSchedule } from '@/api/EnglishInterviewSchedule/EnglishInterviewUpdateSchedule';
import { capacitationScheduleUpdateSchedule } from '@/api/CapacitationSchedule/CapacitationScheduleUpdateSchedule';
import { talkScheduleUpdateSchedule } from '@/api/TalkSchedule/TalkScheduleUpdateSchedule';
// delete schedules
import { destroyEnglishInterviewSchedule } from '@/api/EnglishInterviewSchedule/EnglishInterviewSchedule'
import { destroyCapacitationSchedule } from '@/api/CapacitationSchedule/CapacitationSchedule'
import { destroyTalkSchedule } from '@/api/TalkSchedule/TalkSchedule'

import swal from 'sweetalert'

export default {
    name: 'EmployeeCalendar',
    data: () => ({
        color: '',
        focus: new Date().toISOString().substring(0,10),
        type: 'month',
        typeToLabel: {
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
            '4day': '4 días',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],

        /**
         * MODAL: SCHEDULE
         */
        inputModalScheduleErrors: {},
        initialModalScheduleInputValues: true,
        updateScheduleLoading: false,

        /** */
        emptyText: '- - - - - -'
    }),

    computed: {
        userEmployeeData () {
            return this.$store.getters['user/getUserRoleData']('empleado')
        }
    },

    mounted () {
        this.$refs.calendar.checkChange()
    },

    methods: {
        reloadData() {
            this.getEmployeeCalendarEvents()
        },
        /**
         * OWN CALENDAR METHODS
         */
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = new Date().toISOString().substring(0,10)
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        /**
         * 
         */
        updateRange ({ start, end }) {
            console.log('start:', start, '-- end: ', end);
            this.getEmployeeCalendarEvents()
        },

        // getEmployeeEmployeeCalendar
        getEmployeeCalendarEvents () {

            getEmployeeEmployeeCalendar(this.userEmployeeData.attributes.idToManage)
                .then(({data}) => {
                    this.events = data.data.map(el => {
                        if (el.type == 'EnglishInterviewSchedule') {
                            return {
                                card_type                           : el.type,
                                englishInterviewSchedule_id         : el.attributes.id,
                                //
                                salesAdvisor_id                     : el.attributes.salesAdvisor_id,
                                culturalAdvisor_id                  : el.attributes.culturalAdvisor_id,
                                //
                                programSeasonPostulant_id           : el.attributes.programSeasonPostulant_id,
                                postulant_full_name                 : el.attributes.postulant_full_name,
                                postulant_program_name              : el.attributes.postulant_program_name,
                                postulant_season_name               : el.attributes.postulant_season_name,
                                //
                                start_date                          : el.attributes.start_date,
                                start_time                          : el.attributes.start_time,
                                end_date                            : el.attributes.end_date,
                                end_time                            : el.attributes.end_time,
                                schedule_description                : el.attributes.schedule_description,
                                schedule_status                     : el.attributes.schedule_status,
                                //
                                loading                             : false,
                                // -------
                                name                                : el.attributes.subject,
                                start                               : el.attributes.start,
                                end                                 : el.attributes.end,
                                color                               : el.attributes.schedule_status == 'culminado' ? '#A7C4BC' : (el.attributes.schedule_status == 'pendiente' ? '#EF4A81' : '#5E8B7E'),
                                details                             : el.attributes.schedule_description,
                                link                                : el.attributes.link,
                                timed                               : true,
                            }
                        } else if (el.type == 'CapacitationSchedule') {
                            return {
                                card_type                           : el.type,
                                capacitationsSchedule_id            : el.attributes.id,
                                //
                                // salesAdvisor_id                     : el.attributes.salesAdvisor_id,
                                culturalAdvisor_id                  : el.attributes.culturalAdvisor_id,
                                //
                                capacitationScheduleParticipants    : el.attributes.capacitationScheduleParticipants,
                                //
                                start_date                          : el.attributes.start_date,
                                start_time                          : el.attributes.start_time,
                                end_date                            : el.attributes.end_date,
                                end_time                            : el.attributes.end_time,
                                schedule_description                : el.attributes.schedule_description,
                                schedule_status                     : el.attributes.schedule_status,
                                //
                                loading                             : false,
                                // -------
                                name                                : el.attributes.subject,
                                start                               : el.attributes.start,
                                end                                 : el.attributes.end,
                                // color                               : el.attributes.schedule_status == 'culminado' ? '#C6CBEF' : (el.attributes.schedule_status == 'pendiente' ? '#EF4A81' : '#8186D5'),
                                color                               : el.attributes.schedule_status == 'culminado' ? '#C6CBEF' :  '#8186D5',
                                details                             : el.attributes.schedule_description,
                                link                                : el.attributes.link,
                                timed                               : true,
                            }
                        } else if (el.type == 'TalkSchedule') {
                            return {
                                card_type                           : el.type,
                                talkSchedule_id                     : el.attributes.id,
                                //
                                // salesAdvisor_id                     : el.attributes.salesAdvisor_id,
                                culturalAdvisor_id                  : el.attributes.culturalAdvisor_id,
                                //
                                talkScheduleParticipants            : el.attributes.talkScheduleParticipants,
                                //
                                start_date                          : el.attributes.start_date,
                                start_time                          : el.attributes.start_time,
                                end_date                            : el.attributes.end_date,
                                end_time                            : el.attributes.end_time,
                                schedule_description                : el.attributes.schedule_description,
                                schedule_status                     : el.attributes.schedule_status,
                                //
                                loading                             : false,
                                // -------
                                name                                : el.attributes.subject,
                                start                               : el.attributes.start,
                                end                                 : el.attributes.end,
                                color                               : el.attributes.schedule_status == 'culminado' ? '#FFD07F' : '#FF8243',
                                details                             : el.attributes.schedule_description,
                                link                                : el.attributes.link,
                                timed                               : true,
                            }
                        }


                    })
                })
                .catch(err => {
                    console.log((err));
                    console.log((err.response));
                })
                .finally(() => {
                    console.log('get calendar data end');
                })
        },

         /**
         * MODAL: UPDATE - SCHEDULE
         */
        openModalShedule () {
            this.$bvModal.show('modal-update-schedule')
        },
        closeModalShedule () {
            this.clearModalScheduleInputsAndStates()
            this.$bvModal.hide('modal-update-schedule')
            this.getEmployeeCalendarEvents()
        },

        updateSchedule () {
            this.updateScheduleLoading = true
            this.initialModalScheduleInputValues = false
            this.inputModalScheduleErrors = {}


            const ScheduleFormData = new FormData()
            ScheduleFormData.append('.method', 'put')
            ScheduleFormData.append('start_date', this.selectedEvent.start_date)
            ScheduleFormData.append('start_time', this.selectedEvent.start_time)
            ScheduleFormData.append('end_date', this.selectedEvent.start_date)
            ScheduleFormData.append('end_time', this.selectedEvent.end_time)
            ScheduleFormData.append('link', this.selectedEvent.link)
            ScheduleFormData.append('schedule_description', this.selectedEvent.schedule_description)

            switch (this.selectedEvent.card_type) {
                case 'EnglishInterviewSchedule':
                    this.editEnglishInterviewSchedule(ScheduleFormData)
                    break;
                case 'CapacitationSchedule':
                    this.editCapacitationSchedule(ScheduleFormData)
                    break;
                case 'TalkSchedule':
                    this.editTalkSchedule(ScheduleFormData)
                    break;
                default:
                    console.log('edit schedule break');
                    break;
            }
        },

        editEnglishInterviewSchedule (pScheduleFormData) {
            let IdEnglishInterviewSchedule = this.selectedEvent.englishInterviewSchedule_id // ok
            englishInterviewUpdateSchedule (pScheduleFormData, IdEnglishInterviewSchedule)
                .then(() => {
                    swal('Actualización correcta!', 'Se ha actualizado la entrevista.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalShedule()
                                    break;

                                default:
                                    console.log('updateSchedule-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputModalScheduleErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.updateScheduleLoading = false
                })
        },

        editCapacitationSchedule(pScheduleFormData) {
            let IdCapacitationSchedule = this.selectedEvent.capacitationsSchedule_id // ok
            capacitationScheduleUpdateSchedule (pScheduleFormData, IdCapacitationSchedule)
                .then(() => {
                    swal('Actualización correcta!', 'Se ha actualizado la capacitación.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalShedule()
                                    break;

                                default:
                                    console.log('updateSchedule-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputModalScheduleErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.updateScheduleLoading = false
                })
        },

        editTalkSchedule(pScheduleFormData) {
            let IdTalkSchedule = this.selectedEvent.talkSchedule_id // ok
            talkScheduleUpdateSchedule (pScheduleFormData, IdTalkSchedule)
                .then(() => {
                    swal('Actualización correcta!', 'Se ha actualizado la charla.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalShedule()
                                    break;

                                default:
                                    console.log('updateSchedule-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputModalScheduleErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.updateScheduleLoading = false
                })
        },

        //
        showModalScheduleInputErrors (pInput) {
            if (Object.keys(this.inputModalScheduleErrors).includes(pInput)) return this.inputModalScheduleErrors[pInput]
            else return []
        },

        showModalScheduleInputStatus (pInput) {
            if (this.initialModalScheduleInputValues) return null
            else if (Object.keys(this.inputModalScheduleErrors).includes(pInput)) return false
            else return true
        },

        clearModalScheduleInputsAndStates () {
            this.inputModalScheduleErrors = {}
            this.initialModalScheduleInputValues = true
        },

        /** DELETE  SCHEDULE */
        deleteSchedule () {
            switch (this.selectedEvent.card_type) {
                case 'EnglishInterviewSchedule':
                    this.deleteEnglishInterviewSchedule()
                    break;
                case 'CapacitationSchedule':
                    this.deleteCapacitationSchedule()
                    break;
                case 'TalkSchedule':
                    this.deleteTalkSchedule()
                    break;
                default:
                    console.log('delete schedule break');
                    break;
            }
        },

        deleteEnglishInterviewSchedule () {
            swal('Se eliminará la entrevista.', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            setTimeout(() => {
                                this.selectedOpen = true
                            }, 1)
                            this.selectedEvent.loading = true
                            destroyEnglishInterviewSchedule(this.selectedEvent.englishInterviewSchedule_id)
                                .then(() => {
                                    swal('Eliminado!', 'Entrevista eliminada', 'success')
                                        .then(value => {
                                            switch (value) {
                                                case true:
                                                case null:
                                                    this.getEmployeeCalendarEvents()
                                                    break;
                                                default:
                                                    console.log('delete-eng-interview-swal-break');
                                                    break;
                                            }
                                        })
                                })
                                .finally(() => {
                                    this.selectedEvent.loading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-eng-interview-swal-break');
                            break;
                    }
                })
        },

        deleteCapacitationSchedule () {
            swal('Se eliminará la capacitación.', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            setTimeout(() => {
                                this.selectedOpen = true
                            }, 1)
                            this.selectedEvent.loading = true
                            destroyCapacitationSchedule(this.selectedEvent.capacitationsSchedule_id)
                                .then(() => {
                                    swal('Eliminado!', 'Capacitación eliminada', 'success')
                                        .then(value => {
                                            switch (value) {
                                                case true:
                                                case null:
                                                    this.getEmployeeCalendarEvents()
                                                    break;
                                                default:
                                                    console.log('delete-eng-interview-swal-break');
                                                    break;
                                            }
                                        })
                                })
                                .finally(() => {
                                    this.selectedEvent.loading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-eng-interview-swal-break');
                            break;
                    }
                })
        },

        deleteTalkSchedule () {
            swal('Se eliminará la charla.', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            setTimeout(() => {
                                this.selectedOpen = true
                            }, 1)
                            this.selectedEvent.loading = true
                            destroyTalkSchedule(this.selectedEvent.talkSchedule_id)
                                .then(() => {
                                    swal('Eliminado!', 'Charla eliminada', 'success')
                                        .then(value => {
                                            switch (value) {
                                                case true:
                                                case null:
                                                    this.getEmployeeCalendarEvents()
                                                    break;
                                                default:
                                                    console.log('delete-eng-interview-swal-break');
                                                    break;
                                            }
                                        })
                                })
                                .finally(() => {
                                    this.selectedEvent.loading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-eng-interview-swal-break');
                            break;
                    }
                })
        },


    },
  }
</script>

<style scoped>
.card-agenda {
    border: 1.5px solid;
    border-radius: 5px;
}
@media (max-width: 575px) {
    .card-event-display {
        width: 350px;
    }
}
@media (min-width: 576px) {
    .card-event-display {
        min-width: 350px;
    }
}
/** scrollable */
.card-section-scrollable {
    overflow-x: auto;
}
.card-section-scrollable::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    display: none;
}
@media (max-width: 575px) {
    .card-section-scrollable::-webkit-scrollbar {
        width: 2.5px;
        height: 2.5px;
        display: block;
    }
}
.card-section-scrollable::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: rgba(94, 114, 228, 0.15);
}
.card-section-scrollable::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: rgba(94, 114, 228, 0.25);
}
.card-section-scrollable::-webkit-scrollbar-thumb:hover {
    background-color: rgba(94, 114, 228, 0.35);
}
/** end */
</style>